import React, { Component, Fragment, lazy } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { routehelp } from '../../Routehelper';
import { productsingle, orderReject, ordermanagementlist, order_pdf, orderexport, profilesingle, orderSingle, uommanagementlist, brandmanagementlist, customermanagementlist, orderConfirm, outstandingDays, orderUpdate, productmanagementlist, delete_order, multipleOrderExport } from "../../actions";
import { commonDebounce, getCompanyId, errorRender, fileDownload, formatOfDecimal, isPermission, loaderUpdate, validate, getSeriesValues } from '../../helpers/functions';
import Moment from "moment";
import toastr from "reactjs-toastr";
import { confirmAlert } from "react-confirm-alert";
import { ITEM_PER_PAGES, CURRENT_PAGE, SORT_BY, SORT } from '../../helpers/constants';
import { uploadUrl } from '../../config';
import "react-confirm-alert/src/react-confirm-alert.css";
import "reactjs-toastr/lib/toast.css";
import helperFunc from "../../helpers/helperFuncs";
import Select from 'react-select';
import ProductDropdown from '../productManagement/productDropdown';
import Axios from "axios";

const Entries = lazy(() => import('../../components/entries/entires'));
const OrderNoDropdown = lazy(() => import('./orderNoDropdown'));
const Pagination = lazy(() => import('../../components/pagination/index'));
const ButtonWithItag = lazy(() => import('../../components/buttonwithTagi'));
const Loader = lazy(() => import('../../components/loader'));
const Transporterdropdown = lazy(() => import("../transporterManagement/transporterDropdwon"));
const SchemeDropDown = lazy(() => import('../schemeManagement/schemeDropdwon'));
const Userdropdown = lazy(() => import('../userManagement/userDropdwon'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const DataTable = lazy(() => import('../../components/dataTalble'));
const CardTitle = lazy(() => import('../../components/cardTitle'));
const CustomerDropDown = lazy(() => import('../customerManagement/customerDropdown'));
const SearchInput = lazy(() => import('../../components/searchInput'));
const Wholesalerdropdown = lazy(() => import('../../container/indirectCustomer/wholesalerDropdwon'));
const Buttons = lazy(() => import('../../components/buttons'));

let itemsPerPage = 25;
let currentPage = CURRENT_PAGE;
let sortBy = SORT_BY;
let sort = SORT;
let file = new FormData();
class Ordermanagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webFeatures: {},
      isViewOpen: false,
      isEdit: false,
      uom_name: false,
      maxDate: new Date().toJSON().slice(0, 10).split`-`.join("-"),
      isDisplayDateRange: false,
      isShadeDuplicate: false,
      daterangeMsg: null,
      orderNum: "",
      displayUOM: {},
      yearlyOrder: "currentYear",
      companyFormat: "default",
      check: "",
      name: "",
      code: "",
      isLoading: false,
      brand: "",
      orderStatusList: [
        { value: "confirm", label: "Confirm" },
        { value: "pending", label: "Pending" },
      ],
      orderStatus: "",
      brand_filter: "",
      isFilter: false,
      customer: null,
      isRemoveProduct: false,
      customerEditValue: null,
      indirectCustomerEditValue: null,
      orderNoteUpdate: "",
      excelType: "ALL",
      companyOrderNum: "",
      viewOrder: {},
      newViewOrder: [],
      orderData: {},
      totQty: 0,
      isCopy: false,
      series: "",
      transporter: null,
      transporterName: null,
      scheme: null,
      agent: "",
      exportLoader: false,
      outstandingDaysOfCustomer: 0,
      startdate: "",
      enddate: "",
      exportSdate: "",
      exportEdate: "",
      onlyOnceCall: true,
      indirectCustomer: null,
      filterCustomer: { limit: 50000 },
      serverError: ["orderexportresponse", "orderUploadresponse", "orderConfirm_pack", "ordermanagement_res", "order_update", "order_single", "order_delete"],
      seriesValue : [],
      mappingObj: {
        true: "Confirmed",
        false: "Pending",
        reject: "Rejected"
      },
      tableHeaders: [
        {
          name: 'isActive',
          label: "isChecked",
          type: "multiCheckbox",
          checked: this.masterChecked,
          function: this.handleMasterCheckbox,
          handleCheckboxChange: this.handleCheckboxChange,
          sortable: true,
          isShow: true,
        },
        {
          name: 'companyOrderNum',
          label: "Order No.",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'orderTemplate.name',
          label: "Order Template",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'customer.name',
          label: "Customer",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'agent.name',
          label: "User",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'agent.type',
          label: "User Type",
          type: "text",
          sortable: false,
          isShow: true
        },
        {
          name: 'indirectCustomer.name',
          label: "Indirect Customer",
          type: "text",
          sortable: false,
          isShow: true
        },
        {
          name: 'brand.name',
          label: "Brand",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'totalQuantity',
          label: "Total Quantity",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'totalAmount',
          label: "Total Amount",
          type: "text",
          sortable: true,
          isShow: true
        },
        {
          name: 'orderStatus',
          label: "Status",
          type: "boolean",
          sortable: true,
          isShow: true
        },
        {
          name: 'createdAt',
          label: "CreatedAt",
          type: "date",
          sortable: true,
          isShow: true
        },
        {
          name: 'actions',
          label: "Actions",
          type: "button",
          sortable: false,
          isShow: true,
          allactions: [
            // {
            //   name: "edit",
            //   title: "Edit",
            //   classNameI: "ti-pencil",
            //   className: "btn btn-link text-pramiry action-btn mr-2 p-0",
            //   function: redirect
            // },
            {
              classNameI: "ti-eye",
              dataBackdrop : "static",
              dataKeyboard : false,
              type: "button",
              className: "btn btn-link text-primary action-btn p-0  ",
              datatoggle: "modal",
              datatarget: ".bd-example-modal-lg",
              dataplacement: "top",
              title: "Order Details",
              dataoriginaltitle: "Click to Order Details",
              name: "edit",
              isPermission: true,
              function: this.orderDetails
            },
            {
              name: "print",
              title: "Print",
              classNameI: "fa fa-print",
              className: "btn btn-link text-pramiry action-btn mr-2 ml-2 p-0",
              isPermission: true,
              function: this.callInvoice
            },
            {
              name: "print",
              title: "Print",
              classNameI: "fa fa-copy",
              className: "btn btn-link text-pramiry action-btn mr-2 ml-1 p-0",
              isPermission: true,
              function: this.callInvoiceWithCopy
            },
            {
              name: "delete",
              title: "Delete",
              classNameI: "ti-trash",
              className: "btn btn-link text-danger action-btn p-0 mr-1",
              isPermission: "delete",
              function: this.deleteRow
            }
          ]
        }
      ],
      isMasterChecked : false,
      isBackButton: false,
      filterIndirectCus: {},
      filterCus: {},
      productDetails: [],
      originalOrder: [],
      type: "",
      multipleProducts: [],
      uomError: "",
      filterProduct: {},
      multiplier: 0,
      newMultiplier: 0,
      selectedBrand: "",
      product: "",
      productError: "",
      productTotal: 0,
      totalQuantity: 0,
      subTotal: 0,
      products: [],
      price: 0,
      uom: [
        {
          label: "Please select UOM",
          value: "",
          code: "",
          conFact: ""
        }
      ],
      unitCodes: [],
      shade: [],
      truncateProducts: [],
      isAutoConfirm: "afterConfirm"
    };
  };

  deleteRow = (e, item) => {
    if (window.confirm(`Are you sure you wish to delete "${item.companyOrderNum}" ?`)) {
      this.props.delete_order(item._id);
      e.preventDefault();
    }
  }
  masterChecked = () => {
    return this.state.isMasterChecked;
  };

  handleCheckboxChange = (id) => {
      const updatedList = this.state.orderData.map(item => {
          if (item._id === id) {
              item.checked = !item.checked;
          };
          return item;
      });

      this.setState({
        orderData: updatedList,
        isMasterChecked: updatedList.every(item => item.checked),
    });
  };

  handleMasterCheckbox = (e) => {
    let {checked} = e.target;
    const updatedList = this.state.orderData.map(item => {
      item.checked = checked
      return item;
    });

    this.setState({
        orderData: updatedList,
        isMasterChecked: checked,
    });
  };

  changeEditMode = () => {
    this.setState({
      isEdit: !this.state.isEdit
    }, () => {
      // console.log(this.state.viewOrder);
      this.setState({
        filterCus: {
          customerType: this.state.viewOrder && this.state.viewOrder.type ? this.state.viewOrder.type : null,
          // filter: {
          //   // agent: this.state.viewOrder && this.state.viewOrder.agent && this.state.viewOrder.agent._id ? this.state.viewOrder.agent._id : null
          // }
        },
        orderNoteUpdate: this.state.viewOrder ? this.state.viewOrder.note : "",
        filterIndirectCus: {
          filter: {
            customerType: this.state.viewOrder.type === "wholesaler" ? "Indirect" : "retailer",
            wholesaler: this.state.customerEditValue ? this.state.customerEditValue.value : undefined
          }
        }
      });
    });
  };

  closeModal = () => {
    this.setState({ isEdit: false, productError: null, uomError: null })
    this.loadResult();
  };

  callInvoice = (e, item) => {
    this.props.order_pdf(item._id || "");
    this.setState({
      isCopy: false
    });
  };

  callUOMList = () => {
    let data = {};
    data.filter = { name: '', code: '' };
    this.props.uommanagementlist(data);
  };

  componentDidMount() {
    let data = {};
    this.props.profilesingle(data);
    this.loadResult();
    var params = this.queryStringParse(this.props.location.search);
    if (params && params.dashboard === 'true') {
      // console.log(params);
      this.setState({
        isBackButton: true
      });
    }
    this.callUOMList();
    this.setState({
      seriesValue: helperFunc.seriesTypeList()
    });
  }

  queryStringParse = function (string) {
    let parsed = {};
    if (string) {
      string = string.substring(string.indexOf('?') + 1)
      let p1 = string.split('&')
      p1.forEach(value => {
        let params = value.split('=')
        parsed[params[0]] = params[1]
      });
    }
    return parsed
  }

  componentWillUnmount() {
    currentPage = CURRENT_PAGE;
    itemsPerPage = ITEM_PER_PAGES;
    this.setState = (state, callback) => {
      return;
    };
  }

  loadResult = (flag = false) => {
    this.callforDebounce = commonDebounce(this.callforDebounce, 1000);
    this.setState({ isLoading: true, isFilter: false, isMasterChecked: false });

    if (this.state.isFilter === true) {
      currentPage = 1;
    };

    let data = {};
    data.wantAgentType = this.state.excelType ? this.state.excelType : "ALL";

    // if (data.wantAgentType !== "ALL") {
    //   data.requestFrom = "B2B";
    // }

    // data.requestFrom = this.state.requestFrom !== "" && this.state.requestFrom !== "ALL" ? this.state.requestFrom : undefined;
    // console.log(this.state.customer);

    data.filter = {
      name: this.state.name,
      code: this.state.code,
      // customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined,
      customer: this.state.customer && this.state.customer.length > 0 ? this.state.customer.map((ele) => ele.value) : undefined,
      indirectCustomer: this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined,
      agent: this.state.agent !== "" ? this.state.agent.value : undefined,
      yearlyOrder: this.state.yearlyOrder ? this.state.yearlyOrder : undefined,
      createdAt: {
        from: this.state.startdate,
        to: this.state.enddate
      },
      brand: this.state.brand_filter,
      status: this.state.orderStatus ? this.state.orderStatus.value : ""
    };

    if (this.state.orderNum) {
      data.filter.companyOrderNum = this.state.orderNum;
      currentPage = 1;
    };

    data.sortBy = sortBy;
    data.sort = sort;
    data.skip = (currentPage - 1) * itemsPerPage;
    data.limit = itemsPerPage;
    this.props.ordermanagementlist(data);

    if (!flag) {
      this.props.brandmanagementlist({
        "filter": {
          "code": ""
        },
        "sortBy": "createdAt",
        "sort": "desc",
        "skip": 0,
        "limit": 50
      });
    };
  };

  ondropdownChange = (e, t) => {
    this.setState({ [e]: t });
  }

  confirmExport = () => {
    this.setState({
      isDisplayDateRange: !this.state.isDisplayDateRange,
      daterangeMsg: null,
      exportEdate: this.state.enddate ? this.state.enddate : "",
      exportSdate: this.state.startdate ? this.state.startdate : "",
      companyOrderNum: "",
    });
  };

  pdfExport = () => {
    let data = {
      orderList: []
    };
    let newData = this.state.orderData.filter((ele) => ele.checked);
    newData.forEach((item) => {
      data.orderList.push(item._id);
    });
    if (data.orderList.length > 0){
      this.props.multipleOrderExport(data);
    } else {
        toastr.error("Select at least one order.", { displayDuration: 10000 });           
      }
  };

  export = () => {
    if (this.state.exportSdate && this.state.exportEdate) {
      let sdate = new Date(this.state.exportSdate);
      let edate = new Date(this.state.exportEdate);
      if (edate.getTime() < sdate.getTime()) {
        this.setState({
          daterangeMsg: "End Date must be bigger than start date"
        });
        return true;
      };
      let difffTime = edate.getTime() - sdate.getTime();
      if (difffTime <= 62 * 24 * 60 * 60 * 1000) {

        if (this.state.excelType !== "") {
          this.setState({ exportLoader: true });
          let data = {
            wantAgentType: this.state.excelType,
          };
          // data.requestFrom = this.state.requestFrom !== "" && this.state.requestFrom !== "ALL" ? this.state.requestFrom : undefined;
          data.filter = {
            name: this.state.name,
            code: this.state.code,
            companyOrderNumFrom: this.state.companyOrderNum ? this.state.companyOrderNum.label : undefined,
            customer: this.state.customer && this.state.customer.length > 0 ? this.state.customer.map((ele) => ele.value) : undefined,
            indirectCustomer: this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined,
            agent: this.state.agent !== "" ? this.state.agent.value : undefined,
            createdAt: {
              from: this.state.exportSdate,
              to: this.state.exportEdate
            },
            brand: this.state.brand_filter,
            status: this.state.orderStatus ? this.state.orderStatus.value : ""
          };

          if (this.state.orderNum) {
            data.filter.companyOrderNum = this.state.orderNum;
          };

          data.sortBy = sortBy;
          data.sort = sort;

          // console.log(data);

          this.props.orderexport(data);

        } else {
          toastr.error("Please Select Type  ", "error", {
            displayDuration: 5000,
            width: "400px",
          });
        };
      } else {
        this.setState({
          daterangeMsg: "Select date in 2 months range."
        });
      }
    } else {
      this.setState({
        daterangeMsg: "Please select start date and end date to export the file."
      });
    }
  };

  orderDetails = (ev, item) => {
    // console.log("order", item.products);
    this.setState({
      originalOrder: item.products,
      isViewOpen: true,
      isEdit: false,
      product: '',
      truncateProducts: [],
      newViewOrder: []
    });
    // this.setState({
    //   viewOrder: item,
    //   originalOrder: item,

    //   outstandingDaysOfCustomer: 0,
    //   series: item.series,

    //   transporter: item.transporter ? {
    //     label: item.transporter && item.transporter.name,
    //     value: item.transporter && item.transporter._id
    //   } : null,

    //   scheme: item.scheme ? {
    //     label: item.scheme && item.scheme.name,
    //     value: item.scheme && item.scheme._id
    //   } : null,

    //   customerEditValue: item.customer ? {
    //     label: item.customer && item.customer.name,
    //     value: item.customer && item.customer._id
    //   } : null,

    //   indirectCustomerEditValue: item.indirectCustomer ? {
    //     label: item.indirectCustomer && item.indirectCustomer.name,
    //     value: item.indirectCustomer && item.indirectCustomer._id
    //   } : null,

    //   filterProduct: {
    //     brand: item.brand._id
    //   },
    //   selectedBrand: item.brand._id,
    //   multiplier: item.brand.multiplier,
    //   type: item.type

    // });
    this.props.orderSingle(item._id);
    this.props.outstandingDays(item._id);
    this.props.uommanagementlist({ limit:1000 });

    // this.setState(this.state);
  };

  submit = (item) => {
    if (!this.state.isEdit) {
      confirmAlert({
        title: `Confirm to ${this.state.isEdit ? "Update" : "Submit"}`,
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.orderConfirm(item),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    } else {
      let saveButtonOpen = false;
      let productList = [];
      if (item && item.products && item.products.length > 0) {
        item.products.forEach((ele) => {
          if (ele.isEditOpen) {
            saveButtonOpen = true;
            productList.push(ele.productName);
          }
        });
      }
      if (!saveButtonOpen) {
        this.orderConfirm(item);
      } else {
        alert(`Please save the edited details. Products are ${productList.map((ele) => ele).join(", ")}. Then try to update the order.`)
      }
    };
  };

  orderConfirm = (item) => {
    if (this.state.isEdit) {
      // console.log(item);
      if (item && item.products && item.products.length > 0 && +this.state.viewOrder.totalQuantity > 0 && +this.state.viewOrder.totalAmount > 0) {
        let products = item.products.map((ele) => {
          return {
            ...ele,
            product: ele.product,
            productName: ele.productName,
            productPrice: ele.productPrice,
            attributes: ele.attributes.map((att) => {
              return {
                unitCode: att.unitCode,
                convFact: att.convFact,
                productPrice: ele.productPrice,
                quantity: +att.quantity,
                shadeQuantity: +att.quantity,
                stockType:att.stockType,
                shade: att.name,
                code: att.name,
                name: att.name,
                quantityUnit: att.quantityUnit
              }
            })
          }
        });
        // console.log("products", JSON.stringify(products));
        // console.log(this.state.viewOrder.totalQuantity);
        // console.log(item.indirectCustomer);
        // console.log(item.agent);
        let orderUpdateJson = {
          customer: this.state.customerEditValue ? this.state.customerEditValue.value : item.customer && typeof item.customer === 'object' && item.customer._id ? item.customer : null,
          indirectCustomer: this.state.indirectCustomerEditValue ? this.state.indirectCustomerEditValue.value : item.indirectCustomer && typeof item.indirectCustomer === 'object' && item.indirectCustomer._id ? item.indirectCustomer : null,
          brand: typeof item.brand == "object" ? item.brand._id : item.brand,
          brandMultiplier: this.state.multiplier ? this.state.multiplier : 1,
          agent: item.agent && typeof item.agent == "object" && item.agent._id ? item.agent._id : item.agent,
          scheme: this.state.scheme ? this.state.scheme.value : null,
          transporter: this.state.transporter ? this.state.transporter.value : null,
          series: this.state.series,
          products: products,
          type: item.type,
          note: this.state.orderNoteUpdate,
          totalAmount: this.state.viewOrder.totalAmount,
          totalQuantity: this.state.viewOrder.totalQuantity
        };
        // console.log(orderUpdateJson);
        this.setState({
          isViewOpen: true,
          truncateProducts: [],
          newViewOrder: [],
          product: '',
        });
        this.props.orderUpdate(orderUpdateJson, item._id);
      } else {
        alert("Minimum one Product or Shade of product is required. and then try to update");
      }
    } else {
      let data = {
        transportName: this.state.transporter !== null ? this.state.transporter && this.state.transporter.forSelection ? this.state.transporter.forSelection : this.state.transporter.label : undefined,
        schemeName: this.state.scheme && this.state.scheme.label,
        series: this.state.series
      };
      this.props.orderConfirm(data, item._id);
    };
  };

  setdate = (e) => {
    this.setState({
      startdate: Moment(e.target.valueAsDate).format(
        "YYYY-MM-DD"
      )
    });
  };

  sortBy = (key) => {
    sortBy = key;
    if (sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.loadResult();
  };

  resetfilter = () => {
    let data = {};
    data.sortBy = sortBy;
    data.sort = sort;
    data.skip = (currentPage - 1) * itemsPerPage;
    data.limit = itemsPerPage;
    // data.requestFrom = undefined;
    data.wantAgentType = "ALL";
    this.props.ordermanagementlist(data);
    this.setState({
      isFilter: false,
      isLoading: true,
      isMasterChecked: false,
      customer: null,
      startdate: "",
      enddate: "",
      yearlyOrder: "currentYear",
      indirectCustomer: "",
      agent: "",
      // requestFrom: "",
      brand_filter: "",
      excelType: "ALL",
      orderStatus: ""
      // exportSdate: "",
      // exportEdate: ""
    });
  };

  resetOnlyExport = () => {
    this.setState({
      exportSdate: "",
      exportEdate: "",
      companyOrderNum: "",
      daterangeMsg: null
    });
  };

  toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

  redirectToCreate = () => {
    this.props.history.push(routehelp.order_create);
  };

  handleFile = (event) => {
    if (event.target.files && event.target.files.length) {
      if (event.target.files[0].type === "text/csv") {
        file.append("file", event.target.files[0]);
        file.append("brand", this.state.brand);
      } else {
        toastr.error("Please Select only Csv file ", "error", {
          displayDuration: 5000,
          width: "400px",
        });
      }
    }
  };

  onChange = (e) => {
    const re = /([^\s]+)/;
    const { name, value } = e.target;
    if (value === '' || re.test(value)) {
      this.setState({ [name]: value });
    }
    // this.setState({ [e.target.name]: e.target.value })
  };

  handleNext = () => {
    currentPage = currentPage + 1;
    this.loadResult();
  };

  handlePrevious = () => {
    currentPage = currentPage - 1;
    this.loadResult();
  };

  handlePage = (page) => {
    currentPage = page;
    this.loadResult();
  };

  handleChangeiItemsPerPage = (items) => {
    currentPage = 1;
    itemsPerPage = items;
    this.loadResult();
  };

  changeIndirectCus = (value, name) => {
    this.setState({ [name]: value });
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      
      if (nextProps.ordermanagement_res && nextProps.ordermanagement_res.list && nextProps.ordermanagement_res.list.length > 0) {
        nextProps.ordermanagement_res.list = nextProps.ordermanagement_res.list.map((ele) => {
          ele.checked =false;
          return ele;
        });
        this.setState({orderData: nextProps.ordermanagement_res.list, isMasterChecked: false});

      }

      if (nextProps.uommanagement_res && nextProps.uommanagement_res.list && nextProps.uommanagement_res.list.length > 0) {
        let displayUOMList = {};
        nextProps.uommanagement_res.list.forEach((ele) => {
          displayUOMList[ele.code] = ele.name;
        });
        this.setState({
          displayUOM: displayUOMList
        });
      }

      if (nextProps.outstandingDaysResponse !== undefined && nextProps.outstandingDaysResponse.success === true) {
        if (nextProps.outstandingDaysResponse.list && nextProps.outstandingDaysResponse.list.length > 0) {
          this.setState({ outstandingDaysOfCustomer: nextProps.outstandingDaysResponse.list[0].outstandingDays !== null ? nextProps.outstandingDaysResponse.list[0].outstandingDays : 0 })
        }
        nextProps.outstandingDaysResponse.success = null;
      }

      if (nextProps.uommanagement_res && nextProps.uommanagement_res.count > 0) {
        let displayUOM = {};
        nextProps.uommanagement_res.list.forEach((ele) => {
          displayUOM[ele.code] = ele.name;
        });
      this.setState({
        uomDisplay: displayUOM
      });
      }

      if (nextProps.profile_single && nextProps.profile_single.success === true) {
        // console.log(nextProps.profile_single);
        this.setState({
          companyFormat: nextProps.profile_single.item.reportFormat,
          webFeatures: nextProps.profile_single.item.webFeature,
          isAutoConfirm: nextProps.profile_single.item.stockUpdateOn || "afterConfirm"
        }, () => {
          // console.log(this.state.isAutoConfirm);
          this.updateReportFormat();
        });

      }

      if (this.state.isLoading) {
        this.setState({ isLoading: loaderUpdate(nextProps, "ordermanagement_res") });
      }

      if (this.state.exportLoader) this.setState({ exportLoader: false });

      if (nextProps.order_pdf_res !== undefined && nextProps.order_pdf_res.success === true && nextProps.order_pdf_res.item) {
        if (this.state.isCopy) {
          this.downloadAndCopyPDF(`${uploadUrl}${nextProps.order_pdf_res.item}`, nextProps.order_pdf_res.item);
        } else {
          window.open(`${uploadUrl}${nextProps.order_pdf_res.item}`, "_blank");
        }
        nextProps.order_pdf_res.success = null;
      };

      fileDownload(nextProps, "orderexportresponse", "fileName");
      if (nextProps.orderexportresponse) {
        this.setState({
          daterangeMsg: null,
          // exportEdate: "",
          // exportSdate: "",
          isDisplayDateRange: false
        });
      }

      if (nextProps.multipleorderexportresponse !== undefined && nextProps.multipleorderexportresponse.success === true && nextProps.multipleorderexportresponse.list && nextProps.multipleorderexportresponse.list.length > 0) {
        nextProps.multipleorderexportresponse.list.map((item) => {
          this.downloadPDF(item);
        });
        nextProps.multipleorderexportresponse.success = null;
      };

      this.state.serverError.forEach((el) => errorRender(nextProps, el));

      if (nextProps.orderConfirm_pack !== undefined && nextProps.orderConfirm_pack.success === true) {
        if (nextProps.orderConfirm_pack.item && nextProps.orderConfirm_pack.item.body && nextProps.orderConfirm_pack.item.body.Status === true) {
          toastr.success("Order Has Been Confirmed", "Success", {
            displayDuration: 5000,
          });
          nextProps.orderConfirm_pack.item.body.Status = null;
          this.loadResult();
        }

        //manage response from logic erp
        if (nextProps.orderConfirm_pack.item && nextProps.orderConfirm_pack.item.body && nextProps.orderConfirm_pack.item.body.Status === false) {
          toastr.error(nextProps.orderConfirm_pack.item.body.Message, "Error", { displayDuration: 5000 });
          nextProps.orderConfirm_pack.item.body.Status = null;
        }

      }

      if (nextProps.orderConfirm_pack !== undefined && nextProps.orderConfirm_pack.success === false) {
        if (nextProps.orderConfirm_pack && nextProps.orderConfirm_pack.errors && nextProps.orderConfirm_pack.errors.length > 0) {
          nextProps.orderConfirm_pack.errors.forEach((ele) => {
            toastr.error(ele.msg, "Error", { displayDuration: 5000 });
          });
          this.loadResult();
          nextProps.orderConfirm_pack.success = null;
        }
      }

      if (nextProps.order_reject && nextProps.order_reject.success && nextProps.order_reject.flag) {
        toastr.success("Order Has Been Rejected", "Success", {
          displayDuration: 5000,
        });
        this.loadResult();
        nextProps.order_reject.flag = null;
      }

      if (nextProps.order_delete && nextProps.order_delete.success) {
        toastr.success("Order Has Been deleted successfully", "Success", {
          displayDuration: 5000,
        });
        this.loadResult();
        nextProps.order_delete.success = null;
      }

      if (nextProps.order_update && nextProps.order_update.success) {
        toastr.success("Order Has Been updated successfully", "Success", {
          displayDuration: 5000,
        });
        // this.loadResult();
        let updateData = {
          customer: {
            _id: this.state.customerEditValue ? this.state.customerEditValue.value : "",
            name: this.state.customerEditValue ? this.state.customerEditValue.label : ""
          },
          indirectCustomer:
          {
            _id: this.state.indirectCustomerEditValue ? this.state.indirectCustomerEditValue.value : "",
            name: this.state.indirectCustomerEditValue ? this.state.indirectCustomerEditValue.label : ""
          },
          note: this.state.orderNoteUpdate
        };

        // console.log(updateData);

        this.setState({
          isEdit: false,
          viewOrder: { ...this.state.viewOrder, ...updateData },
          originalOrder: nextProps.order_update.item.products,
        }, () => {
          // console.log(this.state.viewOrder);
        });

        nextProps.order_update.success = null;
      };
      
      this.setState({
        isViewOpen: false
      });

      if (nextProps.order_single && nextProps.order_single.success) {
        // console.log(nextProps.order_single);
        let item = nextProps.order_single.item || {};
        if (item) {
          const uniqueQuantityUnits = new Set();
          item.products.forEach(products => {
              products.attributes.forEach(attribute => {
                uniqueQuantityUnits.add(attribute.unitCode.unitName);
              });
          });
          this.setState({
            viewOrder: item,
            quantityUnits: Array.from(uniqueQuantityUnits),
            // originalOrder: nextProps.order_single.item.products,

            outstandingDaysOfCustomer: 0,
            series: item.series,
            seriesValue: getSeriesValues(item.brand),

            transporter: item.transporter ? {
              label: item.transporter && item.transporter.name,
              value: item.transporter && item.transporter._id
            } : null,

            scheme: item.scheme ? {
              label: item.scheme && item.scheme.name,
              value: item.scheme && item.scheme._id
            } : null,

            customerEditValue: item.customer ? {
              label: item.customer && item.customer.name,
              value: item.customer && item.customer._id
            } : null,

            indirectCustomerEditValue: item.indirectCustomer ? {
              label: item.indirectCustomer && item.indirectCustomer.name,
              value: item.indirectCustomer && item.indirectCustomer._id
            } : null,

            filterProduct: {
              brand: item.brand._id
            },
            selectedBrand: item.brand._id,
            multiplier: item.brandMultiplier,
            newMultiplier: item.brand.multiplier,
            type: item.type

          });
        };
        nextProps.order_single.success = null;
      };

      if (nextProps.product_single && nextProps.product_single.success) {
        let viewOrder = this.state.viewOrder;
        const viewOrderName=  viewOrder.products.filter((ele) => ele.product === nextProps.product_single.item._id)[0].attributes.map(item => item.name);
        const productName = nextProps.product_single.item.attributes.map(attribute => attribute.name);
        const checkName = viewOrderName.every(name => productName.includes(name));

        if (nextProps.product_single.item) {
            let productDetails = this.state.productDetails || [];
            if (!checkName && !this.state.isRemoveProduct) {
              toastr.error("Shade for the selected Product is not found", { displayDuration: 9000 });
            }
            if (productDetails && productDetails.length > 0 && productDetails.findIndex((e) => e._id === nextProps.product_single.item._id) !== -1) {
              let productInd = productDetails.findIndex((e) => e._id === nextProps.product_single.item._id);
              productDetails[productInd] = nextProps.product_single.item;
            } else {
              // console.log(nextProps.product_single.item);
              productDetails.push(nextProps.product_single.item);
            };
  
            this.setState({
              productDetails: productDetails
            }, () => {
              if (this.state.isRemoveProduct) {
                this.callForRemoveProduct();
                this.setState({
                  isRemoveProduct: false,
                })
              } else {
                this.checkingStocks(); 
              }
            })       
        }
        nextProps.product_single.success = null;
      }

      this.state.serverError.forEach((el) => errorRender(nextProps, el));
    }

    return true;

  };
  
  downloadPDF = async (pdfPath) => {
    try {
      const response = await Axios.get(`${uploadUrl}/${pdfPath}`, {
        responseType: 'blob',
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pdfPath);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF', error);
    }
  };

  updateReportFormat = () => {
    // console.log(this.state.webFeatures ? this.state.webFeatures.orderTemplate : "NA");
    if (this.state.webFeatures && !this.state.webFeatures.orderTemplate) {
      let tableHeaders = this.state.tableHeaders || [];
      delete tableHeaders[2];
      this.setState({
        tableHeaders: tableHeaders
      });
    };

    if (this.state.companyFormat === "invoice") {
      let newTableHeaders = this.state.tableHeaders || [];
      newTableHeaders.pop();
      newTableHeaders.push({
        name: 'actions',
        label: "Actions",
        type: "invoice",
        sortable: false,
        isShow: true,
        data: [
          {
            target: "_blank",
            type: "href",
            name: "view",
            classNameI: "ti-eye mr-2",
            className: "btn btn-link action-btn",
          },
          {
            type: "button",
            name: "confirm",
            title: "Confirm",
            classNameI: "ti-check",
            className: "btn btn-link text-pramiry action-btn",
            function: this.submit
          },
          {
            type: "button",
            name: "reject",
            title: "Reject",
            classNameI: "ti-close",
            className: "btn btn-link text-danger action-btn",
            function: this.orderRejected
          }]
      });

      this.setState({
        tableHeaders: newTableHeaders
      });

    }
  };

  callInvoiceWithCopy = (e, item) => {
    this.props.order_pdf(item._id || "");
    this.setState({
      isCopy: true
    });
  };

  downloadAndCopyPDF = (url, filename) => {
    // console.log(url, filename);
    fetch(url).then((response) => response.blob()).then((blob) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        // const dataUrl = reader.result;
        // const anchor = document.createElement('a');
        // anchor.href = dataUrl;
        // anchor.download = filename;
        // document.body.appendChild(anchor);
        // anchor.click();
        // document.body.removeChild(anchor);
        navigator.clipboard.writeText(url).then(() => {
          // console.log('PDF URL copied to clipboard');
          toastr.success("PDF URL copied to clipboard");
        }).catch((error) => {
          toastr.error("Failed to copy PDF URL to clipboard:");
          // console.error('Failed to copy PDF URL to clipboard:', error);
        });
      });
      reader.readAsDataURL(blob);
    }).catch((error) => {
      console.error('Failed to download PDF:', error);
    });

  };

  orderRejected = (data) => {
    confirmAlert({
      title: "Order Reject",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.props.orderReject(data._id),
        },
        {
          label: "No",
          onClick: () => null,
        },
      ],
    });
  };

  onChangeforDebounce = ({ target: { value } }) => {
    if (!/^\s/.test(value)) {
      this.setState({ orderNum: value }, () => {
        this.setState({ isLoading: true });
        this.callforDebounce(value);
      });
    }
  };

  callforDebounce = () => this.loadResult();  

  getQuantity = (items, ite) => {
    return items.map((attri) => {
           if ((JSON.stringify(attri.name) === JSON.stringify(ite.name)) && Number(attri.quantity) >= 0 ) {
              attri.quantity = +attri.quantity + +ite.quantity;
           } 
          return attri;
         });
  };

  addDeleteQuantity = (ite, t) => {

    if (t && t.items && t.items.stockAttributes && t.items.stockAttributes.length > 0 && ite && ite.stockType === "main") {
      t.items.stockAttributes = this.getQuantity(t.items.stockAttributes, ite); 
    };
    if (t && t.items && t.items.forwardStockAttributes && t.items.forwardStockAttributes.length > 0 && ite && ite.stockType === "forward") {
      t.items.forwardStockAttributes = this.getQuantity(t.items.forwardStockAttributes, ite); 
    };
  };

  onClickButton = (index) => {
    if (this.state.selectedButtonIndex === index) {
      this.setState({ selectedButtonIndex: null, uom_name: false });
    } else {
      this.setState({ selectedButtonIndex: index, uom_name: true });
    }
  };

  ondropdownChangeagent = (e, t) => {
    this.setState({ [e]: t }, () => {
      // console.log(e, t)
      if (e === "customerEditValue") { 
        this.setState({ 
          filterIndirectCus: {
            filter: {
              customerType: this.state.viewOrder.type === "wholesaler" ? "Indirect" : "retailer",
              wholesaler: this.state.customerEditValue ? this.state.customerEditValue.value : undefined
            }
          },
          indirectCustomerEditValue: { value: "", label: "" }
        });
      } else if (e === "product") {
        let tempArr = [t.items];
        let uomFilterData = t && t.items && t.items.attributes && t.items.attributes.length > 0 &&
          t.items.attributes[0].unitCodes && t.items.attributes[0].unitCodes.length > 0 ?
          t.items.attributes[0].unitCodes.map((ele) => {
            return {
              label: this.state.displayUOM[ele.unitName] || ele.unitName,
              value: ele.unitName,
              code: ele.unitName,
              conFact: ele.convFact
            }
          }) : [];
          if (t && t.items && t.items.attributes && t.items.attributes.length > 0) {
            t.items.attributes = t.items.attributes.map((attribute) => {
                attribute.unitCodes = attribute.unitCodes.map((unit) => {  
                    unit.selectedQTY = 0;
                    return unit;
                });
                return attribute;
            });
          }    
          let originalProduct = this.state.originalOrder.filter((ele) => JSON.stringify(ele.productName) === JSON.stringify(t.label));
          let isOldProduct = this.state.truncateProducts.filter((ele) => JSON.stringify(ele.product) === JSON.stringify(t.label));
          if (isOldProduct && originalProduct && originalProduct.length > 0 && isOldProduct.length > 0 ) {
            originalProduct[0].attributes.forEach((ite) => {
              this.addDeleteQuantity(ite, t);
            });           
          };

        let shadeData=[];
        t.items.attributes.forEach((item) => {
          const stockQty = t.items.stockAttributes.find((ite)=>ite.name === item.name);
          const forwardStockQty = t.items.forwardStockAttributes.find((ite) => ite.name === item.name);
          if ((stockQty.quantity > 0 && forwardStockQty.quantity > 0) || stockQty.quantity > 0) {
            return shadeData.push(Object.assign(stockQty, {stockType: "main"}, {selectedQTY: 0}, {productName: t.items.name}, {uom : item.unitCodes}));
          } else if (forwardStockQty.quantity > 0) {
            return shadeData.push(Object.assign(forwardStockQty, {stockType: "forward"}, {selectedQTY: 0}, {productName: t.items.name}, {uom : item.unitCodes}));
          } else {
            return shadeData.push(Object.assign(stockQty, {stockType: "main"}, {selectedQTY: 0}, {productName: t.items.name}, {uom : item.unitCodes}));
          };
      });

        this.setState({
          // totalAmount: 0,
          selectedBrand: t.items.brand,
          productTotal: 0,
          totalQuantity: 0,
          subTotal: 0,
          products: tempArr,
          product: t,
          price: t.items.price,
          uom: [...[{
            label: "Please select UOM",
            value: "",
            code: "",
            conFact: ""
          }], ...uomFilterData] || [],
          unitCodes: t.items.attributes || [],
          shade: shadeData ,
        }, () => {
          // console.log("before", this.state.shade);

          const sorter = (a, b) => {
            // console.log(a, b);
            const isNumber = (v) => (+v).toString() === v;
            const aPart = a.name.match(/\d+|\D+/g);
            const bPart = b.name.match(/\d+|\D+/g);
            let i = 0; let len = Math.min(aPart.length, bPart.length);
            while (i < len && aPart[i] === bPart[i]) { i++; };
            if (i === len) {
              return aPart.length - bPart.length;
            };
            if (isNumber(aPart[i]) && isNumber(bPart[i])) {
              return aPart[i] - bPart[i];
            };
            return aPart[i].localeCompare(bPart[i]);
          };

          if (this.state.shade && this.state.shade.length > 0) {
            this.state.shade.sort(sorter);
          };

          // console.log("after", this.state.shade);

          this.setState({
            shade: this.state.shade
          });

        });

      } else if (e === "shade") {
        this.setState({ [e]: t });
        this.calculation(this.state.shade || []);
      };
    })
  };

  shadeCalculateSubTotal = (ele, ite, type = null) => {

    let subTotal = 0;
    const orderIndex = this.state.originalOrder.findIndex((item) => item.productName === ele.productName);
      if (orderIndex > -1) {
        if (this.state.originalOrder[orderIndex] && this.state.originalOrder[orderIndex].attributes && this.state.originalOrder[orderIndex].attributes.length > 0) {
          for (let i = 0; i < this.state.originalOrder[orderIndex].attributes.length; i++) {
            if (this.state.originalOrder[orderIndex].attributes[i].name === ele.name && this.state.originalOrder[orderIndex].attributes[i].unitCode.unitName === ite.unitName) {
              subTotal = +(ite.selectedQTY) *(type === "retailer" ? +this.state.originalOrder[orderIndex].productPrice : +this.state.originalOrder[orderIndex].whsProductPrice) * +this.state.originalOrder[orderIndex].attributes[i].convFact;
            }
          }; 
          let orderShade = this.state.originalOrder[orderIndex].attributes.filter((item) => item.name === ele.name && item.unitCode.unitName === ite.unitName);
          if (orderShade.length === 0) {
            subTotal = parseFloat(ite.convFact) * parseFloat(ite.selectedQTY) * parseFloat(this.state.price) * (type === "retailer" ? 1 : parseFloat(this.state.multiplier || 1)).toFixed(2);
          }
        };
      } else {
        subTotal = parseFloat(ite.convFact) * parseFloat(ite.selectedQTY) * parseFloat(this.state.price) * (type === "retailer" ? 1 : parseFloat(this.state.multiplier || 1)).toFixed(2);
      };
      return subTotal;
  };

  calculation(shadeList = []) {

    // console.log("selectUOM", this.state.uom_data);
    // console.log("productP", this.state.price);

    // console.log("==============called calfun==============");
    // console.log("Shade List", shadeList);

    if (shadeList && shadeList.length > 0) {

      let total = 0;
      let totalQuantity = 0;
      let mainSubTotal = 0;

      shadeList.forEach((ele) => {
        if (Number(ele.quantity) > 0) {
          if (Number(ele.selectedQTY) <= Number(ele.quantity)) {
            if (this.state.unitCodes && this.state.unitCodes.length > 0) {
              this.state.unitCodes.forEach((code) => {
                if (code.name === ele.name) {
                  code.unitCodes.forEach((e) => {
                    let subTotal = 0;
                    ele.uom.forEach((ite) => {
                      if (e.unitName === ite.unitName && ite.selectedQTY > 0) {
                          // console.log("selected UOM", this.state.uom_data);
                          // console.log("convFact", e.convFact);
                          totalQuantity = Number(totalQuantity) + Number(ele.selectedQTY);
                          if (this.state.type === "wholesaler") {
                            subTotal = this.shadeCalculateSubTotal(ele, ite);
                          } else {
                            subTotal = this.shadeCalculateSubTotal(ele, ite, "retailer");
                          };
                          total = parseFloat(total) + parseFloat(subTotal);
                          mainSubTotal = parseFloat(mainSubTotal) + parseFloat(subTotal);
                      };
                    });
                  });
                }
              });
            }
          }
        }
      });

      // console.log("product price", this.state.price);
      // console.log("uom", this.state.uom_data);
      // console.log("type", this.state.utype, "multiplier", this.state.multiplier);
      // console.log("totalPqty", totalQuantity)
      // console.log("subTotal", mainSubTotal);
      // console.log("total", total);
      // console.log("unitcodes", this.state.unitCodes);

      // console.log("================end==============");

      this.setState({
        totalQuantity: totalQuantity,
        // totalAmount: total,
        productTotal: formatOfDecimal(total),
        subTotal: formatOfDecimal(mainSubTotal) 
      });

    }
  };

  QTYfun = (item, name, index, qty = 0, unitName) => {

    let shadeUpdate = this.state.shade;

  if (name === "add") {

      if (shadeUpdate && shadeUpdate.length > 0) {
          shadeUpdate.forEach((ele, i) => {
              if (!ele.selectedQTY) {
                  ele.selectedQTY = 0;
              }

              if (typeof ele.quantity === "string" && ele.quantity !== "0") {
                  ele.quantity = Number(ele.quantity);
              }

              if (typeof ele.quantity === "string" && ele.quantity === "0") {
                  ele.quantity = 0;
              }

              if (i === index) {
                  ele.uom.forEach((el) => {
                      if (el.unitName === unitName) {
                          if (ele.selectedQTY < ele.quantity) {
                              if (typeof ele.selectedQTY === "string") {
                                  ele.selectedQTY = Number(ele.selectedQTY);
                                  el.selectedQTY = Number(el.selectedQTY);
                              }
                              el.selectedQTY++;
                              ele.selectedQTY++;

                          } else {
                              ele.selectedQTY = ele.quantity;
                              alert(`Selected Qty is greather then max qty. Please check max Qty of shade name: ${item.name} and select accordingly`);
                              return true;
                          }
                      }
                  })
              }

          });
      }

  }

  if (name === "minus") {
      if (shadeUpdate && shadeUpdate.length > 0) {
          shadeUpdate.forEach((ele, i) => {

              if (!ele.selectedQTY) {
                  ele.selectedQTY = 0;
              }
              if (i === index) {
                  ele.uom.forEach((el) => {
                      if (el.unitName === unitName && el.selectedQTY - 1 >= 0 ) {
                          if (typeof ele.selectedQTY === "string") {
                              ele.selectedQTY = Number(ele.selectedQTY);
                              el.selectedQTY = Number(el.selectedQTY);
                          }
                          ele.selectedQTY--;
                          el.selectedQTY--; 
                      }
                  })
              }
          });
      }
  }

  if (name === "change") {

      // if (typeof qty === "string" && qty !== "0") {
      //     qty = Number(qty);
      // }

      // if (typeof qty === "string" && qty === "0") {
      //     qty = 0;
      // }

      if (typeof item.quantity === "string" && item.quantity !== "0") {
          item.quantity = Number(item.quantity);
      }

      if (qty < 0) {
          alert("Negative value is not allowed");
          qty = 0;
      }

      if (shadeUpdate && shadeUpdate.length > 0) {
          shadeUpdate.forEach((ele, i) => {

              if (i === index) {
                  ele.uom.forEach((el) => {
                      if (el.unitName === unitName) {
                            if (qty > el.selectedQTY) {
                              if (qty < item.quantity || Number(qty) === item.quantity) {
                                  if ((ele.selectedQTY + (Number(qty) - el.selectedQTY)) > item.quantity) {
                                      alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${item.name} and select accordingly`);
                                      return true;
                                  } else {
                                      ele.selectedQTY =  ele.selectedQTY + (Number(qty) - el.selectedQTY);
                                      el.selectedQTY = qty;
                                  }
                              } else {
                                  alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${item.name} and select accordingly`);
                                  return true;
                              }
                          } else if (qty < el.selectedQTY) {
                              if (qty < item.quantity) {
                                  ele.selectedQTY =  ele.selectedQTY - (el.selectedQTY - Number(qty));
                                  el.selectedQTY = qty;
                              } 
                          } else {
                              ele.selectedQTY =  ele.selectedQTY - Number(qty);
                              el.selectedQTY = qty;
                          };
                      };
                  });

              };

          });
      };

      // console.log(qty);
      // console.log(item.selectedQTY);
  };

    // console.log("shadeupdate", shadeUpdate);
    // console.log("uom", this.state.unitCodes);

    this.setState({
      shade: shadeUpdate
    });

    this.calculation(shadeUpdate);
    // console.log("item", item);
  };

  addMoreItem = () => {
    let valid = this.callCheckValidations();
    if (valid && this.state.totalQuantity > 0) {
      let productsArr = this.callSingleProsuctDetails();
      // let data = this.callFinalJson(productsArr);

      // if (this.state.type === "wholesaler") {
      //   data.wholesaler = data.customer;
      //   data.customer = this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined;
      // };

      let finalData = [];
      finalData.push(productsArr);

      let ptotal = 0;
      if (productsArr && productsArr.length > 0) {
        productsArr.forEach((ele) => {
          ptotal += ele.totalAmount;
        });
      };
      
      let viewOrderObj = this.state.viewOrder;

      if (finalData && finalData.length > 0) {
        finalData.forEach((ele) => {
          if (ele[0].product && this.state.viewOrder.products.findIndex((e, index) => ele[0].product === e.product) < 0) {
            viewOrderObj.products.push(ele[0]);
          } else {
            alert("Duplicate product is not allowed. Try to edit instead of add the product");
          }
        });
      };
      const uniqueQuantityUnits = new Set();
      viewOrderObj.products.forEach(products => {
          products.attributes.forEach(attribute => {
            uniqueQuantityUnits.add(attribute.unitCode.unitName);
          });
      });
      
        this.setState({
          viewOrder: viewOrderObj,
          quantityUnits: Array.from(uniqueQuantityUnits),
          productTotal: formatOfDecimal(ptotal)
      }, () => {
        this.countFinalTotal();
      });
      this.blankAllData();
    } else if (this.state.totalQuantity === 0) {
      alert("Please select a minimum 1 Quantity to add the new product.");
    };

  };

  blankAllData = () => {
    this.setState({
      product: "",
      products: [],
      uom: [{
        label: "Please select UOM",
        value: "",
        code: "",
        conFact: ""
      }],
      shade: [],
      // totalAmount: 0,
      productTotal: 0,
      price: 0,
      unitCodes: [],
      // multiplier: 1,
      subTotal: 0,
      totalQuantity: 0
    });
  };

  countFinalTotal = () => {
    let total = 0;
    let qty = 0;
    if (this.state.viewOrder.products && this.state.viewOrder.products.length > 0) {
      this.state.viewOrder.products.forEach((ele) => {
        if (ele.attributes && ele.attributes.length > 0) {
          ele.attributes.forEach((e) => {
            if (e.quantity) {
              qty += +e.quantity;
            }
          });
        }
        total += +ele.subTotal
      });
    };

    let viewOrder = this.state.viewOrder;
    viewOrder.totalAmount = formatOfDecimal(total);
    viewOrder.totalQuantity = formatOfDecimal(qty);

    // console.log("total", total);
    // console.log("qty", qty);

    if (!isNaN(qty)) {
      this.setState({
        totalQuantity: qty,
        totalAmount: formatOfDecimal(total),
        viewOrder: viewOrder
      });
    }

    // console.log(this.state.totalAmount);
  };

  callCheckValidations = () => {
    let validationFields = [
      // { name: 'customer', label: "Customer", value: this.state.customer, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 500, type: "text", message: "", errName: "customerError" },
      // { name: 'user', label: "User", value: this.state.agent, hasError: false, isRequired: false, isNumber: false, onlyChar: true, maxLength: 500, type: "text", message: "", errName: "userError" },
      // { name: 'uom_data', label: "UOM", value: this.state.uom_data, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "uomError" },
      // { name: 'transporter', label: "Transporter", value: this.state.transporter, hasError: false, isRequired: false, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "transporterError" },
      // { name: 'series', label: "Series", value: this.state.series, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "seriesError" },
      // { name: 'brand', label: "Brand", value: this.state.brand, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "brandError" },
      // { name: 'type', label: "Customer Type", value: this.state.type, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "typeError" },
      { name: 'product', label: "Product", value: this.state.product, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "productError" },
      // { name: 'indirectCustomer', label: "Indirect Customer", value: this.state.indirectCustomer, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 500, type: "text", message: "", errName: "wholesalerError" }
    ];

    // if (this.state.type !== "wholesaler") {
    //   validationFields.splice(8, 1);
    // };

    // if (this.state.type === "wholesaler") {
    //   validationFields.splice(0, 1);
    // };

    // console.log(validationFields);

    let { newArr, valid } = validate(validationFields);

    newArr.map((el) => this.setState({ [el.errName]: el.message }));

    return valid;
  };

  callSingleProsuctDetails = () => {

    let productsArr = [{
      product: this.state.product && this.state.product.value ? this.state.product.value : undefined,
      productName: this.state.product && this.state.product.label ? this.state.product.label : undefined,
      productErpCode: "",
      productPrice: this.state.price,
      attributes: [],
      subTotal: 0,
      // totalProductQty: 0,
      subTotalQuantity: 0,
      totalAmount: 0
    }];

    // console.log("shade", this.state.shade);
    // console.log("uom List", this.state.uom);
    // console.log("unitCodes", this.state.unitCodes);

    if (this.state.shade && this.state.shade.length > 0) {
      this.state.shade.forEach((sh) => {
        if (this.state.unitCodes && this.state.unitCodes.length > 0) {
          // console.log('c-1');
          this.state.unitCodes.forEach((code) => {
            if (JSON.stringify(sh.name) === JSON.stringify(code.name) && Number(sh.selectedQTY) > 0) {
              // console.log('c-2');
              if (code && code.unitCodes.length > 0) {
                // console.log('c-3');
                code.unitCodes.forEach((cd) => {
                  sh.uom.forEach((ite) => {
                    if (JSON.stringify(cd.unitName) === JSON.stringify(ite.unitName) && Number(ite.selectedQTY) > 0) {
                        // console.log('c-4');
                        productsArr[0].subTotal = 0;
                        productsArr[0].attributes.push({
                            unitCode: {unitCode : cd.unitCode, unitName: cd.unitName,  convFact: cd.convFact ? cd.convFact : 1, shade: sh.name },
                            convFact: cd.convFact || 1,
                            productPrice: this.state.price,
                            quantity: formatOfDecimal(ite.selectedQTY),
                            shadeQuantity: formatOfDecimal(ite.selectedQTY),
                            availableQty: sh.quantity - sh.selectedQTY,
                            stockType: sh.stockType,
                            shade: sh.name,
                            code: sh.name,
                            name: sh.name,
                            price: 0,
                            quantityUnit: ite.unitName || "NA"
                        });

                        if (this.state.type === "wholesaler") {
                            productsArr[0].subTotal  = this.shadeCalculateSubTotal(sh, ite);
                            // console.log("1", productsArr[0].subTotal);
                        } else {
                            productsArr[0].subTotal  = this.shadeCalculateSubTotal(sh, ite, "retailer");
                            // console.log("2", productsArr[0].subTotal);
                        }
                        productsArr[0].totalAmount = parseFloat(productsArr[0].totalAmount) + parseFloat(productsArr[0].subTotal);
                        productsArr[0].subTotalQuantity += Number(sh.selectedQTY);

                        // console.log("totalAMount", productsArr[0].totalAmount);
                    }
                  });
                });
              }
            }
          });
        }
      });
    }

    productsArr[0].subTotal = formatOfDecimal(productsArr[0].totalAmount);
    productsArr[0].totalAmount = formatOfDecimal(productsArr[0].totalAmount);
    productsArr[0].subTotalQuantity = formatOfDecimal(productsArr[0].subTotalQuantity);

    let copiedProductsArr = JSON.parse(JSON.stringify(productsArr));
    this.setState({
      newViewOrder: [...this.state.newViewOrder ,copiedProductsArr]
    });
    return productsArr;
  };

  callFinalJson = (productsArr) => {
    const data = {
      brand: this.state.brand && this.state.brand.value ? this.state.brand.value : undefined,
      agent: this.state.agent && this.state.agent.value ? this.state.agent.value : undefined,
      type: this.state.type,
      transporter: this.state.transporter && this.state.transporter.value ? this.state.transporter.value : undefined,
      series: this.state.series || undefined,
      requestFrom: "B2B",
      totalQuantity: this.state.totalQuantity,
      totalAmount: parseFloat(this.state.totalAmount).toFixed(2),
      tax: 0,
      totalSaleAmount: parseFloat(this.state.totalAmount).toFixed(2),
      totalPaidAmount: parseFloat(this.state.totalAmount).toFixed(2),
      totalPendingAmount: 0,
      note: this.state.note,
      products: productsArr,
      latitude: "",
      logitude: "",
      customer: this.state.customer && this.state.customer.value ? this.state.customer.value : undefined,
      company: getCompanyId(localStorage.getItem('type')),
      paymentTerms: this.state.paymentTerms,
      deliveryStation: this.state.deliveryStation,
      dispatchDate: this.state.dispatchDate
      // wholesaler: this.state.indirectCustomer && this.state.indirectCustomer.value ? this.state.indirectCustomer.value : undefined
    }

    return data;
  };

  removeProductDetails = (viewOrderProducts, index, item) => {
    viewOrderProducts.products.forEach((ele, ind) => {
      if (ind === index && item.productName === ele.productName) {
        // console.log(ele);
        let deleteList = [];
        if (ele.attributes && ele.attributes.length > 0) {
          ele.attributes.forEach((attr) => {
            deleteList.unshift({
              product: ele.productName,
              shade: attr.name,
              qty: attr.quantity,
              stockType: attr.stockType,
              time: new Date().getTime()
            });
          });
        }

        let deleteProduct = this.state.truncateProducts.filter((list) => list.product === deleteList[0].product); 
        let newViewOrder = this.state.newViewOrder.filter((arr) => arr[0].productName === item.productName); 
        if (deleteProduct.length === 0  && newViewOrder.length === 0) {
          this.setState({
          truncateProducts: this.state.truncateProducts.concat(deleteList),
          });
        };       
        viewOrderProducts.products.splice(ind, 1);
      }
    });  
    
    const uniqueQuantityUnits = new Set();
    viewOrderProducts.products.forEach(products => {
      products.attributes.forEach(attribute => {
        uniqueQuantityUnits.add(attribute.unitCode.unitName);
      });
    });
   
    this.setState({
      viewOrder: viewOrderProducts, 
      quantityUnits: Array.from(uniqueQuantityUnits),
    }, () => {
      this.countFinalTotal();
    });
  };
  callForRemoveProduct = () => {
    let shouldSkipDeletion = false;
    let viewOrderProducts = this.state.viewOrder || {};
    const productDetails = this.state.productDetails || [];
    const productIndex = productDetails && productDetails.findIndex((ele) => ele.name === this.state.removeItem.productName);
      if (productIndex > -1) {
        if (productDetails && productDetails.length > 0) {
          if (viewOrderProducts.products && viewOrderProducts.products.length > 0) {
            if (this.state.originalOrder && this.state.originalOrder.length > 0) {
              const findIndex = this.state.originalOrder && this.state.originalOrder.findIndex((ele) => ele.productName === this.state.removeItem.productName);
              const viewOrderName=  viewOrderProducts.products.filter((ele) => ele.productName === this.state.removeItem.productName)[0].attributes.map(item => item.name);
              const productName = productDetails[productIndex].attributes.map(attribute => attribute.name);
              const checkName = viewOrderName.every(name => productName.includes(name));
      
              if (findIndex > -1) {
                viewOrderProducts.products.forEach((ele) => {
                  if (ele.productName === this.state.removeItem.productName) {
                   if (checkName) {
                     ele.attributes.forEach((attri) => {
                       const mainQuantity = productDetails[productIndex].stockAttributes.filter((ele) => ele.name === attri.name)[0].quantity;
                       const forwardQuantity = productDetails[productIndex].forwardStockAttributes.filter((ele) => ele.name === attri.name)[0].quantity;
                       if (this.state.originalOrder[findIndex] && this.state.originalOrder[findIndex].attributes && this.state.originalOrder[findIndex].attributes.length > 0) {
                         for (let i = 0; i < this.state.originalOrder[findIndex].attributes.length; i++) {
                           if (attri.name === this.state.originalOrder[findIndex].attributes[i].name) {
                             if (Number(forwardQuantity) > 0 && attri.stockType === "main" && shouldSkipDeletion === false) {
                                 alert(`You can not delete this item as the Stock type is changed`);
                                 shouldSkipDeletion = true;
                                 return;
                             } else if (Number(mainQuantity) > 0 && attri.stockType === "forward" && shouldSkipDeletion === false) {
                               alert(`You can not delete this item as the Stock type is changed`);
                               shouldSkipDeletion = true;
                               return;
                             } else {
                               return;
                             }
                           };
                         };
                       };
                     })
                   } else {
                    alert(`You can not delete this item as the shade is not available`);
                    shouldSkipDeletion = true;
                    return;
                   }
                  }
                  this.setState({
                    isRemoveProduct: false,
                  })
                  if (shouldSkipDeletion) return;
                    if (ele.productName === this.state.removeItem.productName) {
                      this.removeProductDetails(viewOrderProducts, this.state.removeIndex, this.state.removeItem);
                    }
                })
              } else {
                this.removeProductDetails(viewOrderProducts, this.state.removeIndex, this.state.removeItem);
                }
            };
          };

          let newViewOrder = this.state.newViewOrder;
          if (newViewOrder && newViewOrder.length > 0 ) {
            let newData = newViewOrder.filter(arr => arr[0].productName !== this.state.removeItem.productName);
            this.setState({
              newViewOrder: newData,
            });
          };
        };
      };
  }

  removeProduct = (item, index) => {
    this.props.productsingle(item.product);
    this.setState({
      removeItem: item,
      removeIndex: index,
      isRemoveProduct: true,
    });

    
  };

  editProduct = (item, index) => {
    let viewOrder = this.state.viewOrder || {};

    //edit option enable
    if (viewOrder.products && viewOrder.products.length > 0) {
      viewOrder.products.forEach((ele, ind) => {
        if (ind === index) {
          ele.isEditOpen = true;
        }
      });
    };

    //call product details
    this.props.productsingle(item.product);

    this.setState({
      viewOrder: viewOrder,
    });

  };

  commanCalculateSubTotal = (ele, attri, type, newOrderValue) => {

    if (newOrderValue &&  newOrderValue.length === 0) {
      return ele.subTotal = +attri.quantity * +this.state.productPrice * (type === "retailer" ? 1: +this.state.multiplier || 1) * attri.convFact;
    } else {
      return  ele.subTotal = +attri.quantity * +this.state.productPrice * (type === "retailer" ? 1: +this.state.multiplier || 1) * attri.convFact;
    };
  };

  calculateSubTotalOfOriginalOrder = (ele, attri, type, newOrderValue) => {

    const orderIndex1 = this.state.originalOrder.findIndex((item) => item.productName === ele.productName);
    if (orderIndex1 > -1) {
      let orderShade = this.state.originalOrder[orderIndex1].attributes.filter((item) => item.name === attri.name && item.unitCode.unitName === attri.unitCode.unitName);
      if (orderShade.length > 0) {
        for (let i = 0; i < this.state.originalOrder[orderIndex1].attributes.length; i++) {
          if (this.state.originalOrder[orderIndex1].attributes[i].name === attri.name && this.state.originalOrder[orderIndex1].attributes[i].unitCode.unitName === attri.unitCode.unitName) {
            return ele.subTotal = +attri.quantity * (type === "retailer" ? +this.state.originalOrder[orderIndex1].productPrice : +this.state.originalOrder[orderIndex1].whsProductPrice) * +this.state.originalOrder[orderIndex1].attributes[i].convFact;
          }
        };
      } else {
        return ele.subTotal = this.commanCalculateSubTotal(ele, attri, type, newOrderValue);
      }
    } else {
      return ele.subTotal = this.commanCalculateSubTotal(ele, attri, type);
    };
  };

   calculateSubTotalFornewViewOrder = (orderIndex, attri, ele, type) => {

    if (this.state.newViewOrder[orderIndex] && this.state.newViewOrder[orderIndex][0].attributes && this.state.newViewOrder[orderIndex][0].attributes.length > 0) {
      for (let i = 0; i < this.state.newViewOrder[orderIndex][0].attributes.length; i++) {
        if (this.state.newViewOrder[orderIndex][0].attributes[i].name === attri.name && this.state.newViewOrder[orderIndex][0].attributes[i].unitCode.unitName === attri.unitCode.unitName) {
          return this.calculateSubTotalOfOriginalOrder(ele, attri, type);  
        }
      };
      let newOrderValue = this.state.newViewOrder[orderIndex][0].attributes.filter((item) => item.name === attri.name && item.unitCode.unitName === attri.unitCode.unitName);
        return this.calculateSubTotalOfOriginalOrder(ele, attri, type, newOrderValue);
    };
  };

  calculateSubTotalForOriginalOrder = (orderIndex, attri, ele, type) => {

    if (this.state.originalOrder[orderIndex] && this.state.originalOrder[orderIndex].attributes && this.state.originalOrder[orderIndex].attributes.length > 0) {
      for (let i = 0; i < this.state.originalOrder[orderIndex].attributes.length; i++) {
        if (this.state.originalOrder[orderIndex].attributes[i].name === attri.name && this.state.originalOrder[orderIndex].attributes[i].unitCode.unitName === attri.unitCode.unitName) {
          return ele.subTotal = +attri.quantity * (type === "retailer" ? +this.state.originalOrder[orderIndex].productPrice : +this.state.originalOrder[orderIndex].whsProductPrice) * +this.state.originalOrder[orderIndex].attributes[i].convFact;
        }
      };
      let orderShade = this.state.originalOrder[orderIndex].attributes.filter((item) => item.name === attri.name && item.unitCode.unitName === attri.unitCode.unitName);
      if (orderShade && orderShade.length === 0) {
        return  ele.subTotal = +attri.quantity * +this.state.productPrice * (type === "retailer" ? 1: +this.state.multiplier || 1) * attri.convFact;
      }
    };
  };

  originalOrderCalculateSubTotal = (attri, ele, type = null) => {
    if (this.state.originalOrder && this.state.originalOrder.length > 0) {
      const orderIndex = this.state.originalOrder.findIndex((item) => item.productName === ele.productName);
      return  this.calculateSubTotalForOriginalOrder(orderIndex, attri, ele, type);
    };
  };

  calculateSubTotalForAll = (attri, ele, type = null) =>{

    if (this.state.newViewOrder && this.state.newViewOrder.length > 0) {
      const orderIndex = this.state.newViewOrder.findIndex((item) => item[0].productName === ele.productName);
      if (orderIndex > -1) {
       return  this.calculateSubTotalFornewViewOrder(orderIndex, attri, ele, type);
      } else {
        return this.originalOrderCalculateSubTotal(attri, ele, type);
      };
    } else {
      return this.originalOrderCalculateSubTotal(attri, ele, type);
    };  
  };

  confirmEditProduct = (index) => {
    let viewOrder = this.state.viewOrder || {};

    // edit option enable
    if (viewOrder.products && viewOrder.products.length > 0) {
      viewOrder.products.forEach((ele, ind) => {
        if (ind === index) {
          if (ele.attributes && ele.attributes.length > 0) {
            let openProducts = ele.attributes;
            ele.attributes = ele.attributes.filter((attr) => +attr.quantity > 0 || +attr.shadeQuantity > 0);
            // console.log("attributes", ele, ele.attributes);
            if (ele.attributes && ele.attributes.length > 0) {
              if (this.state.isShadeDuplicate) {
                ele.isEditOpen = false;
                this.setState({isShadeDuplicate: false});
              } else {
                ele.subTotal = 0;
                ele.subTotalQuantity = 0;
                ele.attributes.forEach((attri) => {
                  if (viewOrder.type === "wholesaler") {
                    ele.subTotal += this.calculateSubTotalForAll(attri, ele);
                  } else {
                    ele.subTotal += this.calculateSubTotalForAll(attri, ele, "retailer");
                  };
                  ele.subTotalQuantity += +attri.quantity;
                });
                ele.subTotalQuantity = parseFloat(ele.subTotalQuantity).toFixed(2);
                ele.subTotal = parseFloat(ele.subTotal).toFixed(2);
                ele.isEditOpen = false;
              }
            } else {
              ele.attributes = openProducts || [];
              alert("Minimum one shade qty is required to save this product.")
            };
          };
        };
      });
    };

    this.setState({
      viewOrder: viewOrder
    }, () => {
      this.countFinalTotal();
    });

  };

  checkingavailableQtyAndStockType = (stockQty, forwardStockQty, type) => {
    if (type === "checkingavailableQty") {
      if ((stockQty > 0 && forwardStockQty > 0) || stockQty > 0) {
          return stockQty;
      } else if (forwardStockQty > 0) {
          return forwardStockQty;
      } else {
          return 0;
      };
    };

    if (type === "chekingStockType") {
      if ((stockQty > 0 && forwardStockQty > 0) || stockQty > 0) {
          return "main";
      } else if (forwardStockQty > 0) {
          return "forward";
      } else {
          return null;
      };
    };

    if (type === "main" || type === "forward") {
      if (type === "forward") {
        return forwardStockQty;
      } else if (type === "main") {
        return stockQty;
      } else {
        return 0;
      }; 
    };
};

checkingavailableQty = (findedProduct, fatt, stockType) => {
  const stockQty = findedProduct[0].stockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity;
  const forwardStockQty = findedProduct[0].forwardStockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity;
 
  if (stockType) {
    for (let i = 0; i < this.state.newViewOrder.length; i++) {
        if (this.state.newViewOrder[i][0].productName === findedProduct[0].name) {
          for (let j = 0; j < this.state.newViewOrder[i][0].attributes.length; j++) {
              if (this.state.newViewOrder[i][0].attributes[j].availableQty >= 0) {
                if (this.state.newViewOrder[i][0].attributes[j].stockType === "main" && this.state.newViewOrder[i][0].attributes[j].name === fatt.name) {
                  return this.checkingavailableQtyAndStockType(this.state.newViewOrder[i][0].attributes[j].availableQty, 0, stockType);
                } else if (this.state.newViewOrder[i][0].attributes[j].stockType === "forward" && this.state.newViewOrder[i][0].attributes[j].name === fatt.name) {
                    return this.checkingavailableQtyAndStockType(0, this.state.newViewOrder[i][0].attributes[j].availableQty, stockType);
                }
              } 
          }
        } 
    };
      return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, stockType);
  } else {
        const findIndex = this.state.originalOrder.findIndex((ele) => ele.productName === findedProduct[0].name);
        const findIndex1 = this.state.newViewOrder.findIndex((ele) => ele[0].productName === findedProduct[0].name);
          if (findIndex1 > -1) {
            for (let j = 0; j < this.state.newViewOrder[findIndex1][0].attributes.length; j++) {
                if (this.state.newViewOrder[findIndex1][0].attributes[j].name === fatt.name) {
                  if (this.state.newViewOrder[findIndex1][0].attributes[j].stockType === "main") {
                    return this.checkingavailableQtyAndStockType(this.state.newViewOrder[findIndex1][0].attributes[j].availableQty, 0, "checkingavailableQty");
                  } else if (this.state.newViewOrder[findIndex1][0].attributes[j].stockType === "forward") {
                      return this.checkingavailableQtyAndStockType(0, this.state.newViewOrder[findIndex1][0].attributes[j].availableQty, "checkingavailableQty");
                  }
                } 
            }
            if (this.state.originalOrder[findIndex] && this.state.originalOrder[findIndex].attributes && this.state.originalOrder[findIndex].attributes.length > 0) {
              for (let i = 0; i < this.state.originalOrder[findIndex].attributes.length; i++) {
                if (fatt.name === this.state.originalOrder[findIndex].attributes[i].name) {
                  let addQuantity = 0;
                  this.state.originalOrder[findIndex].attributes.forEach(attribute => {
                    if (attribute.name === fatt.name) {
                      addQuantity += parseFloat(attribute.quantity === "" ? 0 : attribute.quantity);
                    }                  
                  })
                  if (this.state.originalOrder[findIndex].attributes[i].stockType === "main" && this.state.originalOrder[findIndex].attributes[i].name === fatt.name ) {
                    return this.checkingavailableQtyAndStockType(Number(stockQty) + addQuantity, 0, "checkingavailableQty");
                  } else if (this.state.originalOrder[findIndex].attributes[i].stockType === "forward" && this.state.originalOrder[findIndex].attributes[i].name === fatt.name) {
                    return this.checkingavailableQtyAndStockType(0, Number(forwardStockQty) + addQuantity, "checkingavailableQty");
                  }
                }
              }
            };
            return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "checkingavailableQty");
          } else {
            if (this.state.originalOrder[findIndex] && this.state.originalOrder[findIndex].attributes && this.state.originalOrder[findIndex].attributes.length > 0) {
              for (let i = 0; i < this.state.originalOrder[findIndex].attributes.length; i++) {
                if (fatt.name === this.state.originalOrder[findIndex].attributes[i].name) {
                  if (this.state.originalOrder[findIndex].attributes[i].stockType === "main" && Number(forwardStockQty) > 0) {
                    return this.checkingavailableQtyAndStockType(0, 0, "checkingavailableQty");
                  } else if (this.state.originalOrder[findIndex].attributes[i].stockType === "forward" && Number(stockQty) > 0) {
                    return this.checkingavailableQtyAndStockType(0, 0, "checkingavailableQty");
                  }
                }
              }
            };
            return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "checkingavailableQty");
          }
  };
};

chekingStockType = (findedProduct, fatt) => {
    const stockQty = findedProduct[0].stockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity;
    const forwardStockQty = findedProduct[0].forwardStockAttributes.filter((ele) => ele.name === fatt.name)[0].quantity;
   
    if (this.state.originalOrder && this.state.originalOrder.length > 0) {
      const orderIndex = this.state.originalOrder.findIndex((item) => item.productName === findedProduct[0].name);
      if (orderIndex > -1) {
        if (this.state.originalOrder[orderIndex] && this.state.originalOrder[orderIndex].attributes && this.state.originalOrder[orderIndex].attributes.length > 0) {
          for (let i = 0; i < this.state.originalOrder[orderIndex].attributes.length; i++) {
            if (this.state.originalOrder[orderIndex].attributes[i].name === fatt.name) {
              if (stockQty > 0) {
                if (this.state.originalOrder[orderIndex].attributes[i].stockType === "main") {
                  return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
                } else {
                  return this.checkingavailableQtyAndStockType(0, 1, "chekingStockType");
                }
              } else if (forwardStockQty > 0) {
                if (this.state.originalOrder[orderIndex].attributes[i].stockType === "forward") {
                  return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
                } else {
                  return this.checkingavailableQtyAndStockType(1, 0, "chekingStockType");
                }
              } else {
                return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
              };
            };
            let orderShade = this.state.originalOrder[orderIndex].attributes.filter((ite) => ite.name === fatt.name);
            if (orderShade.length === 0) {
              if ((stockQty > 0 && forwardStockQty > 0) || stockQty > 0) {
                return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
              } else if (forwardStockQty > 0) {
                return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
              } else {
                return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
              };
            };
          }; 
        }
      } else {
        return this.checkingavailableQtyAndStockType(stockQty, forwardStockQty, "chekingStockType");
      }
    } 
};

  checkingStocks = () => {

    // console.log(this.state.productDetails, JSON.stringify(this.state.viewOrder));
   
    let viewOrder = this.state.viewOrder || {};

    if (viewOrder && viewOrder.products && viewOrder.products.length > 0) {
      viewOrder.products.forEach((eleP) => {  

        if (eleP.isEditOpen) {
          let findedProduct = this.state.productDetails.filter((e) => e._id === eleP.product);
          // console.log(findedProduct);

         if (findedProduct && findedProduct.length > 0) {
            if (findedProduct[0].attributes && findedProduct[0].attributes.length > 0) {
              let newAttr = [];

           // console.log(findedProduct[0].attributes);

              findedProduct[0].attributes.forEach((fatt) => {
                // console.log(fatt.name, eleP.attributes);
                // console.log("index=>", eleP.attributes.findIndex((p) => JSON.stringify(p.name) === JSON.stringify(fatt.name)));
                fatt.unitCodes.forEach((el)=>{
                  if (eleP.attributes.findIndex((p) => JSON.stringify(p.name) === JSON.stringify(fatt.name) && el.unitName === p.unitCode.unitName) > -1) {
                  // shade is already available
                  let findAttInd = eleP.attributes.findIndex((p) => p.name === fatt.name && el.unitName === p.unitCode.unitName);
                  let findAtt = eleP.attributes.filter((p) => p.name === fatt.name && el.unitName === p.unitCode.unitName);
                  let stockType=  eleP.attributes.filter((item,i)=>{return item.name === fatt.name && el.unitName === item.unitCode.unitName })[0].stockType;
                  let originalQty = this.checkingavailableQty(findedProduct, fatt, stockType);
                  if (this.state.isAutoConfirm === "afterConfirm") {
                      eleP.attributes[findAttInd].availableQty = +originalQty - +findAtt[0].shadeQuantity;     
                    } else {
                      eleP.attributes[findAttInd].availableQty = +originalQty;
                    }
                    newAttr.push(eleP.attributes[findAttInd]);
                  } else {
                    // console.log(fatt);
                    // console.log(eleP);

                    // find unitCode
                    if (eleP.attributes && eleP.attributes.length > 0) {
                      let finalUnitCode = fatt.unitCodes.find((unit)=> unit.unitName === el.unitName);
                      if (finalUnitCode) {
                        // console.log(finalUnitCode);
                        //shade is not available
                        newAttr.push({
                          convFact: finalUnitCode.convFact,
                          description: fatt.description,
                          code: fatt.code,
                          name: fatt.name,
                          price: fatt.price,
                          quantity: 0,
                          quantityUnit: el.unitName,
                          shadeQuantity: 0,
                          stockType: this.chekingStockType(findedProduct, fatt), 
                          availableQty: this.checkingavailableQty(findedProduct, fatt, ''),
                          unitCode: { convFact: finalUnitCode.convFact, shade: fatt.name, unitCode: finalUnitCode.unitCode, unitName: finalUnitCode.unitName},
                        });
                      } else {
                        // console.log("shade unitcode is missing", eleP.name, fatt.name, uniCodeSelectByDefault);
                      }
                    } else {
                      // console.log(findedProduct);
                      let finalUnitCode = fatt.unitCodes.filter((filt) => filt.unitName === findedProduct[0].defaultUOM.value)[0];
  
                      if (finalUnitCode) {
                        finalUnitCode.shade = fatt.name;
                        newAttr.push({
                          convFact: finalUnitCode.convFact,
                          description: fatt.description,
                          code: fatt.code,
                          name: fatt.name,
                          price: fatt.price,
                          quantity: 0,
                          quantityUnit: finalUnitCode.unitName,
                          shadeQuantity: 0,
                          stockType: this.chekingStockType(findedProduct, fatt),
                          availableQty: this.checkingavailableQty(findedProduct, fatt, ''),
                          unitCode: { convFact: finalUnitCode.convFact, shade: fatt.name, unitCode: finalUnitCode.unitCode, unitName: finalUnitCode.unitName}
                        });
                      }
                      // console.log("attribite array is missing", eleP.name, fatt.name);
                    };
  
                  };
                })
              });

              findedProduct[0].attributes[0].unitCodes.forEach((el)=>{
                const viewOrderName=  eleP.attributes.map(item => item.name);
                const productName = findedProduct[0].attributes.map(attribute => attribute.name);
                const checkName = viewOrderName.filter(name => !productName.includes(name));
                if (checkName.length > 0) {
                  let newName = [...new Set(checkName)]
                  newName.forEach((name)=>{
                    
                    eleP.attributes.forEach((ele)=>{
                      if (name === ele.name && el.unitName === ele.unitCode.unitName){
                        let findAttInd = eleP.attributes.findIndex((p) => p.name === ele.name && el.unitName === p.unitCode.unitName);
                        if (findAttInd > -1) {
                          eleP.attributes[findAttInd].availableQty = 0;
                        newAttr.push(eleP.attributes[findAttInd]);
                    }
                      }
                    })
                  })
                }
              })

              if (newAttr && newAttr.length > 0) {
                // console.log(newAttr);
                eleP.attributes = newAttr;
              };

            };
          };
          this.setState({
            productPrice:findedProduct[0].price,
          });
        };

      });
    };
    const uniqueQuantityUnits = new Set();
    viewOrder.products.forEach(products => {
      products.attributes.forEach(attribute => {
          uniqueQuantityUnits.add(attribute.unitCode.unitName);
      });
    });
    

    this.setState({
      quantityUnits: Array.from(uniqueQuantityUnits),
      viewOrder: viewOrder
    });

  };

  disabledOfShadeQty = (attri, productName) => {
    const productDetails = this.state.productDetails.filter((item) => item.name === productName);
    if (!attri) {
      return true;
    }
    if (productDetails && productDetails.length > 0) {
      if (productDetails[0].stockAttributes.filter((ele) => ele.name === attri.name).length > 0) {
        const mainQuantity = productDetails[0].stockAttributes.filter((ele) => ele.name === attri.name)[0].quantity;
        const forwardQuantity = productDetails[0].forwardStockAttributes.filter((ele) => ele.name === attri.name)[0].quantity;
        const findIndex = this.state.originalOrder.findIndex((ele) => ele.productName === productName);
          if (this.state.originalOrder[findIndex] && this.state.originalOrder[findIndex].attributes && this.state.originalOrder[findIndex].attributes.length > 0) {
            for (let i = 0; i < this.state.originalOrder[findIndex].attributes.length; i++) {
              if (attri.name === this.state.originalOrder[findIndex].attributes[i].name) {
                if ((Number(forwardQuantity) > 0 && attri.stockType === "main") || (Number(mainQuantity) > 0 && attri.stockType === "forward") || (Number(mainQuantity) === 0 && Number(forwardQuantity) === 0 && Number(this.state.originalOrder[findIndex].attributes[i].shadeQuantity) === 0)) {
                  return true;
                } else {
                  return false;
                }
              } 
              let orderShade = this.state.originalOrder[findIndex].attributes.filter((ite) => ite.name === attri.name);
              if (orderShade.length === 0) {
                if ((Number(forwardQuantity) > 0 && attri.stockType === "main") || (Number(mainQuantity) > 0 && attri.stockType === "forward")) {
                  return true;
                } else {
                  return false;
                }
              };
            };
          };
      } else {
      return true;
      }
    } else {
      return true;
    }
  };

  commonChangeEditQty = (attri, order, qty) => {
    let currentSumQuantity = 0;
    let oldSumQuantity = Number(attri.availableQty);
    let viewOrder = this.state.viewOrder.products.filter((ite) => ite.productName === order.productName);
        viewOrder[0].attributes.forEach(attribute => {
          if (attribute.name === attri.name && attri.unitCode.unitName === attribute.unitCode.unitName) {
            currentSumQuantity += parseFloat(qty === "" ? 0 : qty);
          } else {
              if (attribute.name === attri.name && attribute.quantity !== "" ) {
                currentSumQuantity += parseFloat(attribute.quantity);
              }
          }
          });
        order.attributes.forEach(attribute => {
          if (attribute.name === attri.name) {
            oldSumQuantity += parseFloat(attribute.quantity);
          }
        });
        if (currentSumQuantity > oldSumQuantity) {
          alert(`Selected Qty is greather then maximum qty. Please check maximum qty of shade name: ${attri.name} and select accordingly`);
          return true;
        } 
  };
  changeEditQty = (product, shade, attri, qty = 0) => {
    // console.log(product, attri, index, qty);

    if (typeof attri.shadeQuantity === "string" && attri.shadeQuantity !== "0") {
      attri.shadeQuantity = Number(attri.shadeQuantity);
    }

    if (qty < 0) {
      alert("Negative value is not allowed");
      qty = 0;
    };
    
    if (this.state.newViewOrder && this.state.newViewOrder.length > 0) {
      const findIndex = this.state.newViewOrder.findIndex((ele) => ele[0].productName === product.productName);
      if (findIndex > -1) {
        if (this.state.newViewOrder[findIndex] && this.state.newViewOrder[findIndex][0].attributes  && this.state.newViewOrder[findIndex][0].attributes.length > 0) {
            if (this.commonChangeEditQty(attri, this.state.newViewOrder[findIndex][0], qty)) {
              return true;
            };
        };
      } else {
        if (this.state.originalOrder && this.state.originalOrder.length > 0) {
          const findIndex = this.state.originalOrder.findIndex((ele) => ele.productName === product.productName);
            if (this.state.originalOrder[findIndex] && this.state.originalOrder[findIndex].attributes  && this.state.originalOrder[findIndex].attributes.length > 0) {
              if (this.commonChangeEditQty(attri, this.state.originalOrder[findIndex], qty)) {
                return true;
              }
            };
        };
      };
    } else {
      if (this.state.originalOrder && this.state.originalOrder.length > 0) {
        const findIndex = this.state.originalOrder.findIndex((ele) => ele.productName === product.productName);
          if (this.state.originalOrder[findIndex] && this.state.originalOrder[findIndex].attributes  && this.state.originalOrder[findIndex].attributes.length > 0) {
            if (this.commonChangeEditQty(attri, this.state.originalOrder[findIndex], qty)) {
              return true;
            }
          };
      };
    };

    let viewOrder = this.state.viewOrder || {};
    if (viewOrder.products && viewOrder.products.length > 0) {
      const findIndex = viewOrder.products.findIndex((ele) => ele.product === product.product);
     if (attri) {
       const uomIndex = viewOrder.products[findIndex].attributes.findIndex((ele) => ele.name === attri.name && ele.unitCode.unitName === attri.unitCode.unitName);
       if (findIndex > -1) {
         viewOrder.products[findIndex].attributes[uomIndex].shadeQuantity = qty !== "" ? formatOfDecimal(qty) : qty;
         viewOrder.products[findIndex].attributes[uomIndex].quantity = qty !== "" ? formatOfDecimal(qty) : qty;
         // console.log(viewOrder.products[findIndex].attributes[index]);
       }
     };
    };

    // console.log(this.state.viewOrder);

    this.setState({
      viewOrder: viewOrder
    });

  };

  render() {
    const { ordermanagement_res, brandmanagement_res } = this.props;
    const totResult = ordermanagement_res !== undefined ? ordermanagement_res.count : 0;
    // console.log(ordermanagement_res);
    let { orderNum } = this.state;

    const finalUpdatedRes = ordermanagement_res || {};

    if (this.state.companyFormat === "invoice") {
      if (finalUpdatedRes && finalUpdatedRes.list && finalUpdatedRes.list.length > 0) {
        finalUpdatedRes.list.forEach((ele) => {
          if (ele.isRejected) {
            ele.orderStatus = "reject"
          } else {
            ele.orderStatus = ele.isErpConfirm
          }
        });
      }
    } else {
      if (finalUpdatedRes && finalUpdatedRes.list && finalUpdatedRes.list.length > 0) {
        finalUpdatedRes.list.forEach((ele) => {
          ele.orderStatus = ele.isErpConfirm
        });
      }
    }

    // console.log("this.state.reportFormat" , this.state.companyFormat);
    // console.log("finalUpdatde", finalUpdatedRes);

    // const { orderNum } = this.state;

    return (
      <main className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-sm-12 mt-4">

              <div className="card card-shadow mb-4">
                {
                  this.state.isBackButton ? <div className="mr-3 mt-2 text-right">
                    <Link to={routehelp.maindashboard} className="btn btn-primary">Back</Link>
                  </div> : null
                }
                <div className="card-header d-flex">
                  <div className="card-title col-9">Order Management</div>
                </div>
                <div className="card-body">
                  <div
                    id="bs4-table_wrapper"
                    className="dataTables_wrapper action-btn-fix container-fluid dt-bootstrap4  p-0"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        {totResult !== undefined ? (
                          <Entries
                            itemsPerPage={itemsPerPage}
                            handleChangeiItemsPerPage={
                              this.handleChangeiItemsPerPage
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div
                          id="bs4-table_filter"
                          className="dataTables_filter  d-flex flex-row justify-content-end align-items-center"
                        >
                          <SearchInput onChangeforDebounce={this.onChangeforDebounce} value={orderNum} />
                          {/* <div className="col-8">
                            <select
                              className="form-control"
                              name="excelType"
                              value={this.state.excelType}
                              onChange={this.onChange}
                              id="excelType"
                            >
                              <option value="">Select a Type</option>
                              <option value="ALL">All</option>
                              <option value="AG">Agent</option>
                              <option value="ASM">Area Sales Manager</option>
                              <option value="ASM2">Area Sales Manager 2</option>
                              <option value="SASM">Sr. AREA SALES MANAGER</option>
                            </select>
                          </div> */}

                          {/* {this.state.exportLoader === true ? <div className="mr-3"> <i className="fa fa-spinner fa-spin fa-2x fa-fw text-primary"> </i> </div> */}
                          {/* : */}

                          <ButtonWithItag
                            disabled={this.state.exportLoader}
                            onclick={this.confirmExport}
                            classNameI="fa tai-ico-Export"
                            type="button"
                            className="btn btn-primary mr-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Export as a file"
                            data-original-title="Click to Upload all"
                          />
                          {/* } */}

                          <ButtonWithItag
                            classNameI="ti-filter"
                            onclick={this.toggleFilter}
                            type="button"
                            className="btn btn-primary navbar-toggler right-sidebar-toggler"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Filter"
                            data-original-title="Click for Filter option"
                          />

                          { isPermission("order", "create") ? 
                            <ButtonWithItag
                              classNameI="ti-plus"
                              onclick={this.redirectToCreate}
                              type="button"
                              className="btn btn-primary navbar-toggler right-sidebar-toggler ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Order create"
                              data-original-title="Click for order creation option"
                            />
                          : null }

                          <ButtonWithItag
                            classNameI="tai-ico-file-pdf1"
                            onclick={this.pdfExport}
                            type="button"
                            className="btn btn-primary navbar-toggler right-sidebar-toggler ml-1 fs"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Download- Pdf"
                            data-original-title="Click for order creation option"
                          />

                          {/* <ButtonWithItag classNameI="fa tai-ico-Export" type="button" className="btn btn-primary" datatoggle="modal" datatarget=".bd-example-modal-lg" dataplacement="top" title="" data-original-title="Click to download all" /> */}
                        </div>
                      </div>

                      {
                        this.state.isDisplayDateRange ?
                          <div className="row mx-0 w-100">
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label htmlFor="Mobile" className="control-label">Start Date</label>
                                <input maxLength="10" type="date" className="form-control" max={this.state.maxDate} name="exportSdate" value={this.state.exportSdate} onChange={(e) => this.onChange(e)} id="startdate" placeholder="Start Date" />
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                              <div className="form-group">
                                <label htmlFor="Mobile" className="control-label">End Date</label>
                                <input maxLength="10" type="date" className="form-control" max={this.state.maxDate} name="exportEdate" value={this.state.exportEdate} onChange={(e) => this.onChange(e)} id="enddate" placeholder="End Date" />
                              </div>
                            </div>
                            <div className="col-12 col-md-3">
                                <OrderNoDropdown
                                  label="Order From"
                                  value={this.state.companyOrderNum}
                                  ondropdownChange={(e) => this.ondropdownChange("companyOrderNum", e)}
                                  placeholder="Select an Order Number"
                                />
                            </div>
                            <div className="col-12 col-md-1">
                              <div className="form-group">
                                <label htmlFor="Mobile" className="control-label d-block">&nbsp;</label>
                                <button type="button" disabled={this.state.exportLoader} className="btn btn-primary" onClick={this.export}>Export</button>
                              </div>
                            </div>

                            <div className="col-12 col-md-2 ml-0">
                              <div className="form-group">
                                <label htmlFor="Mobile" className="control-label d-block">&nbsp;</label>
                                <button type="button" disabled={this.state.exportLoader} className="btn btn-danger" onClick={this.resetOnlyExport}>Reset Export</button>
                              </div>
                            </div>

                            {this.state.daterangeMsg ? <div className="col-12 col-md-8">
                              <small className="alert alert-danger w-100 d-block">{this.state.daterangeMsg}</small>
                            </div> : null}
                            {/* <button type="button" disabled={this.state.exportLoader} className="btn btn-primary" onClick={this.export}>Export</button> */}
                          </div> : null
                      }

                    </div>

                    {
                      this.state.isLoading ? <Loader /> :
                        (finalUpdatedRes !== undefined && finalUpdatedRes.count === 0) || !finalUpdatedRes ?
                          <DataTable headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={""} props={this.props}  moduleName={"order"}/> :
                          finalUpdatedRes && finalUpdatedRes.count > 0 ?
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="table-responsive">
                                  <DataTable mappingObj={this.state.mappingObj} headers={this.state.tableHeaders} sortByCol={this.sortBy} sortByvalue={sortBy} sortvalue={sort} data={finalUpdatedRes} props={this.props} moduleName={"order"} />
                                </div>
                              </div>
                            </div> :
                            <p className="text-center">Something Went Wrong</p>}
                    {
                      totResult !== undefined ? (
                        <Pagination
                          handleChangeiItemsPerPage={
                            this.handleChangeiItemsPerPage
                          }
                          handlePage={this.handlePage}
                          handleNext={this.handleNext}
                          handlePrevious={this.handlePrevious}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          totResult={totResult}
                          key={currentPage}
                        />
                      ) : (
                        ""
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          style={{ display: "none" }}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <CardTitle title="Order Details" type="h5" />
                <button
                  type="button"
                  className="close cursor-pointer"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.closeModal()}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {
                  this.state.isViewOpen ?
                    <Fragment>
                      <div className="ml-6 mr-6 mt-6 mb-6">
                        <Loader />
                      </div>
                    </Fragment>
                    :
                    <Fragment>
                      {
                        !this.state.viewOrder.isErpConfirm ?
                          <div className="row mb-2">
                            <div className="col-12 text-center">
                              {isPermission("order", "update") ? 
                                  <button type="button"
                                  className="btn btn-primary"
                                  onClick={(e) => this.changeEditMode()}>
                                    {!this.state.isEdit ? "Enable Edit Mode" : "Disable Edit Mode"}
                                  </button>
                                : null}
                            </div>
                          </div> : null
                      }

                      <div className="row">

                        <div className="col-sm-6">

                          <div className="">
                            Logic Order No : &nbsp;
                            {this.state.viewOrder && this.state.viewOrder.erpOrderCode ? this.state.viewOrder.erpOrderCode : "N/A"}
                          </div>

                          <div className="">
                            Order No : &nbsp;
                            {this.state.viewOrder && this.state.viewOrder.companyOrderNum}
                          </div>

                          {/* {
                      this.state.viewOrder &&
                        this.state.viewOrder.customer && this.state.isEdit ?
                        ""
                        :
                        this.state.viewOrder &&
                          this.state.viewOrder.customer && this.state.viewOrder.customer.name ?
                          <Fragment>Customer<br /></Fragment> :
                          <Fragment>Customer<br /></Fragment>
                    } */}

                        </div>

                        <div className="col-sm-6 text-md-right">
                          <div>
                            {this.state.viewOrder
                              ? Moment(this.state.viewOrder.createdAt).format(
                                "DD-MM-YYYY H:mm"
                              )
                              : "N/A"}
                          </div>
                          <div className="">
                            <div>
                              Outstanding Days : &nbsp;
                              {this.state.outstandingDaysOfCustomer}
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="row">
                            {
                              this.state.viewOrder &&
                                this.state.viewOrder.customer && this.state.isEdit ?
                                <div className="col-12 col-md-6 test-ll">
                                  <CustomerDropDown
                                    ondropdownChange={(e) => this.ondropdownChangeagent("customerEditValue", e)}
                                    placeholder="Search Customer"
                                    value={this.state.customerEditValue}
                                    dontPreLoad={this.state.isEdit && this.state.filterCus.customerType ? false : true}
                                    filterBy={this.state.filterCus}
                                  />
                                  {/* {console.log(this.state.filterCus)} */}
                                </div>
                                :
                                <div className="col-12 col-md-6 test-ll">
                                  <span>Customer</span><br />
                                  {this.state.viewOrder && this.state.viewOrder.customer && this.state.viewOrder.customer.name ?
                                    <span>{this.state.viewOrder.customer.name}</span> : <span>N/A</span>
                                  }
                                </div>
                            }
                            {
                              this.state.viewOrder.customer && this.state.viewOrder.customer.createFrom && this.state.viewOrder.customer.createFrom === "mobile" ?
                                <div className="">
                                  <label className="d-block w-100">&nbsp;</label>
                                  <div className="d-flex align-items-center text-bold">
                                    <span className="text-success">New Customer</span>&nbsp;(created from mobile application)
                                  </div>
                                </div> : null
                            }
                            {
                              this.state.viewOrder && this.state.viewOrder.indirectCustomer && this.state.isEdit ?
                                <div className="col-12 col-md-6">
                                  {/* <strong>Indirect Customer</strong> <br />
                                  <span>
                                  {this.state.viewOrder && this.state.viewOrder.indirectCustomer && this.state.viewOrder.indirectCustomer.name
                                    ? this.state.viewOrder.indirectCustomer.name : null}
                                  </span>{" "} */}
                                  <Wholesalerdropdown
                                    isWhLabel={true}
                                    label="Indirect Customer"
                                    ondropdownChange={(e) => this.changeIndirectCus(e, "indirectCustomerEditValue")}
                                    placeholder="Search Indirect Customer"
                                    value={this.state.indirectCustomerEditValue}
                                    dontPreLoad={this.state.filterIndirectCus && this.state.filterIndirectCus.filter && this.state.filterIndirectCus.filter.customerType ? false : true}
                                    filterBy={this.state.filterIndirectCus}
                                  />
                                </div>
                                :
                                <div className="col-12 col-md-6">
                                  <span>Indirect Customer</span><br />
                                  {this.state.viewOrder && this.state.viewOrder.indirectCustomer && this.state.viewOrder.indirectCustomer.name ?
                                    <span>{this.state.viewOrder.indirectCustomer.name}</span> : <span>N/A</span>
                                  }
                                </div>
                            }

                          </div>

                          <div className="row my-3">
                            <div className="col-12 col-md-6">
                              <strong>Brand</strong>:
                              <strong> {
                                this.state.viewOrder &&
                                this.state.viewOrder.brand &&
                                this.state.viewOrder.brand.name
                              }</strong>
                            </div>
                          </div>
                        </div>

                        {
                          this.state.isEdit ?
                            <div className="col-12 border border-dotted alert-primary mb-2 py-3">
                              <div className="row">
                                <div className="form-group col-lg-6">
                                  <ProductDropdown
                                    isLabel={true}
                                    ondropdownChange={(e) => this.ondropdownChangeagent("product", e)}
                                    placeholder="Search Product"
                                    value={this.state.product}
                                    errMessage={this.state.productError}
                                    dontPreload={this.state.selectedBrand ? false : true}
                                    filterBy={this.state.filterProduct}
                                  />
                                </div>
                              </div>

                              {
                                this.state.shade && this.state.shade.length > 0 && this.state.product ?
                                    <div className='w-100'>                                                                               
                                        <div className='col-lg-6'>
                                            <div
                                                className="table-responsive product-table"
                                            >
                                                <table
                                                    className="table"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>&nbsp;</th>
                                                            <th><div className='ml-2'>shade Name </div></th>
                                                            <th><div className='ml-2'> Qty</div> </th>
                                                            <th><div>Max Qty </div></th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.shade.map((ele, idx) =>
                                                    <tbody>
                                                        <tr>
                                                            <td scope="row"><div className='uombtn'>
                                                            <a
                                                                name=""
                                                                id=""
                                                                className="btn btn-light uom-sidebtn"
                                                                onClick={(e) => this.onClickButton(idx)}
                                                                role="button"
                                                            >
                                                                <i className={this.state.uom_name && this.state.selectedButtonIndex === idx ? "fa fa-angle-up" : "fa fa-angle-down"} style={{ float: 'right' }}></i>
                                                            </a>
                                                        </div>
                                                        </td>
                                                            <td>
                                                            <Inputfield noLabel="true" type="text" className="form-control" name={idx} id={ele.name + "_" + idx} placeholder="Shade name" value={ele.name} disabled="true" />
                                                            </td>
                                                            <td>
                                                            <div key={idx + "select_qty"}>
                                                                    <input type="number" className="form-control border-radius-0 text-center ml-2" name="quantity" id={ele.name + "_quantity_" + idx} placeholder="QTY" value={ele.selectedQTY} disabled="true" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                            {formatOfDecimal(ele.quantity) || 0}
                                                            {ele.stockType === "forward" ? <span className='text-primary'><b> [FWD] </b>  </span> : <span></span>}
                                                            </td>
                                                        </tr>
                                                        {this.state.selectedButtonIndex === idx ?
                                                            ele.uom.map((el, idxe) =>
                                                                <tr className="inner-row" key={idx}>
                                                                    <td>
                                                                        &nbsp;
                                                                    </td>
                                                                    <td  key={idxe + "name"}>
                                                                        <Inputfield noLabel="true" type="text" className="form-control" name={idxe} id={el.unitName + "_" + idxe} placeholder="Shade name" value={this.state.uomDisplay[el.unitName] ? this.state.uomDisplay[el.unitName] : el.unitName} disabled="true" />
                                                                    </td>

                                                                    <td className="qty-ml" key={idx + "minus"}>
                                                                        <div className='d-flex align-items-center ml-2'>
                                                                            <button className="btn btn-danger border-radius-0" onClick={(e) => this.QTYfun(ele, "minus", idx, e.target.value, el.unitName)} type="button">-</button>
                                                                            <input type="number" className="form-control min-80 border-radius-0 text-center" name="quantity" id={ele.name + "_quantity_" + idx} placeholder="QTY" onChange={(e) => this.QTYfun(ele, "change", idx, e.target.value, el.unitName)} max={ele.quantity} min={0} value={el.selectedQTY} />
                                                                            <button className="btn btn-primary border-radius-0" onClick={(e) => this.QTYfun(ele, "add", idx, e.target.value, el.unitName)} type="button">+</button>
                                                                        </div>
                                                                    </td>
                                                                    <td key={idx + "plus"}>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            : null
                                                        }
                                                    </tbody>
                                                    ) }
                                                    
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    : this.state.product ? <p className="col-12 mt-2 mb-2 text-center text-danger">Stock is unavailable</p> : null
                              }
                              <div className="row">
                                <div className='col-6'>
                                  <label>Total Amount Per Product</label>
                                  <p className="form-control border border-primary rounded text-right">{this.state.productTotal || 0} /-</p>
                                </div>
                              </div>
                              <div className="col-md-6 mb-4 mt-3 offset-md-3 text-center">
                                <Buttons onclick={this.addMoreItem} type="button" className="btn btn-success mr-1" name="Add" />
                              </div>
                            </div> : null
                        }


                            <div className="col-sm-12">
                              <div className="table-responsive">
                                <table className="table table-bordered table-striped mb-0">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="bg-primary text-white">
                                        Design No.
                                      </th>
                                      <th className="bg-primary text-white" scope="col">
                                        Shade
                                      </th>
                                      { this.state.quantityUnits ?
                                          this.state.quantityUnits.map((item, i) => 
                                            <th className="bg-primary text-white" scope="col" key={i}>
                                              {this.state.uomDisplay[item] ? this.state.uomDisplay[item] : item}
                                            </th>
                                          ) : null
                                      }
                                      <th className="bg-primary text-white" scope="col">
                                        Qty.
                                      </th>
                                      <th className="bg-primary text-white" scope="col">
                                        Rate
                                      </th>
                                      <th className="bg-primary text-white" scope="col">
                                        Total
                                      </th>
                                      {
                                        this.state.isEdit ?
                                          <th className="bg-primary text-white" scope="col">
                                            Action
                                          </th> : null
                                      }
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.viewOrder &&
                                      this.state.viewOrder.products &&
                                      this.state.viewOrder.products.map((item, index) => {
                                        // shadequan = 0;
                                        // eslint-disable-next-line
                                        this.state.totQty = 0;
                                        item.attributes.map((attriItem) => {
                                          // eslint-disable-next-line
                                          return this.state.totQty = this.state.totQty + parseFloat(attriItem.shadeQuantity || 0);
                                        });
                                        // { console.log(item) }
                                        const uniqueShades = [...new Set(item.attributes.map(attr => attr.name))];

                                        return uniqueShades.map((shade, i) => {
                                          const shadeAttributes = item.attributes.filter(attr => attr.name === shade); 
                                        return (
                                          <tr key={index + "product"}>
                                            {i === 0 && 
                                            <td key={index + "qty"} rowSpan={uniqueShades.length}>{item.productName}</td>
                                            }                                           
                                            <td className='shade-cell' key={index + "shade"}>
                                              {shade} {shadeAttributes[0].stockType === "forward" ? <span className='text-primary'><b> [FWD] </b></span> : ""}  { item.isEditOpen ?  <span className='text-success '>{ parseFloat(Number(shadeAttributes[0].availableQty).toFixed(2)) || 0 }</span> : null}
                                            </td>
                                            {this.state.quantityUnits.map((unit, j) => {
                                              const unitAttribute = shadeAttributes.find(attr => attr.unitCode.unitName === unit);
                                              return (
                                                  item.isEditOpen ?   
                                                    <td> 
                                                      <input type="number" disabled={this.disabledOfShadeQty(unitAttribute, item.productName)} className="form-control input-width min-80 border-radius-0 text-center" name="quantity"  placeholder="QTY"
                                                      onChange={(e) => this.changeEditQty(item, shade, unitAttribute, e.target.value)}  min={0} value={unitAttribute ? unitAttribute.quantity : 0} />
                                                    </td>
                                                      : 
                                                    <td key={j + "unitQty"}>{unitAttribute ? unitAttribute.quantity === "" ? 0 : unitAttribute.quantity : 0}</td>
                                                );
                                            })}
                                                {i === 0 && (
                                                <>
                                                    <td rowSpan={uniqueShades.length}>{formatOfDecimal(this.state.totQty) || 0}</td>
                                                    <td rowSpan={uniqueShades.length}>{item.retailerPrice ? item.retailerPrice : item.productPrice}</td>
                                                    <td rowSpan={uniqueShades.length}>{item.subTotal}</td>
                                                    {
                                                      this.state.isEdit ?
                                                        <td rowSpan={uniqueShades.length}>
                                                          {
                                                            item.isEditOpen ?
                                                              <button className="btn btn-success mr-1" title="Save" onClick={(e) => this.confirmEditProduct(index)}>
                                                                <i className="fa fa-check"></i>
                                                              </button> :
                                                              <button className="btn btn-primary mr-1" title="Edit" onClick={(e) => this.editProduct(item, index)}>
                                                                <i className="fa fa-pencil"></i>
                                                              </button>
                                                          }
                                                          <button className="btn btn-danger" title="Remove" onClick={(e) => this.removeProduct(item, index)}>
                                                            <i className="fa fa-trash" ></i>
                                                          </button>
                                                        </td> : null
                                                      }
                                                </>
                                            )}

                                          </tr>
                                        );
                                      });
                                      })}
                                      { this.state.quantityUnits && this.state.quantityUnits.length > 0 && 
                                        <tr>
                                          <td colSpan={2} className='text-right'><b>Total :</b></td>
                                            {this.state.quantityUnits.map((unit, unitIndex) => {
                                                let totalQuantity = 0;
                                                this.state.viewOrder.products.forEach((productData) => {
                                                    productData.attributes.forEach((item) => {
                                                        if (item.unitCode.unitName === unit) {
                                                            totalQuantity += Number(item.quantity);
                                                        }
                                                    });
                                                });
                                                return <td key={unitIndex + "totalQuantity"}><b>{formatOfDecimal(totalQuantity)}</b></td>;
                                            })}
                                        </tr>
                                      }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                    
                        <div className="col-sm-4 ml-auto max-227">
                          <table className="table table-bordered table-striped">
                            <tbody>
                              <tr>
                                <td className="bg-primary text-white">
                                  <strong>TOTAL</strong>
                                </td>
                                <td className="bg-primary text-white">
                                  <strong>
                                    {this.state.viewOrder &&
                                      this.state.viewOrder.totalAmount}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="col-12 row">
                          <div className=" form-group col-12 col-lg-6">

                            <Transporterdropdown
                              ondropdownChange={(e) => this.ondropdownChangeagent("transporter", e)}
                              placeholder="Search Transporter"
                              value={this.state.transporter}
                            // className="form-control form-control-square"
                            />
                          </div>
                          <div className=" form-group col-12 col-lg-6">
                            <SchemeDropDown
                              ondropdownChange={(e) => this.ondropdownChangeagent("scheme", e)}
                              placeholder="Search Scheme"
                              value={this.state.scheme}
                            />
                          </div>
                          <div className=" form-group col-12 col-lg-6">
                            <label className="control-label">Series</label>
                            <select
                              className="form-control"
                              name="series"
                              value={this.state.series}
                              onChange={(e) => this.onChange(e)}
                              id="excelType"
                            >
                              {this.state.seriesValue.map((item, index) => {
                                return (<option value={item.name} key={index}>{item.name ? item.name : "Please select series"} </option>)
                              })}
                            </select>
                          </div>
                          <div className="form-group col-12 col-lg-6">
                            <label htmlFor="exampleInputName1" className="control-label">Agency</label>
                            <Inputfield disabled={true} type="text" name="agency" value={this.state.viewOrder.agency && this.state.viewOrder.agency.name ? this.state.viewOrder.agency.name : "NIL"} className="form-control" id="agency" ariadescribedby="emailHelp" placeholder="Name" readOnly />
                          </div>
                        </div>

                        {/* <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Payment Terms:</strong>&nbsp;
                      {this.state.viewOrder && this.state.viewOrder.paymentTerms}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Delivery Station:</strong>&nbsp;
                      {this.state.viewOrder && this.state.viewOrder.deliveryStation}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="border p-4">
                      <strong>Dispatch Date:</strong>&nbsp;
                      {this.state.viewOrder
                        ? Moment(this.state.viewOrder.dispatchDate).format(
                          "DD-MM-YYYY"
                        )
                        : ""}
                    </div>
                  </div> */}

                        <div className="col-sm-12">
                          <div className="border p-4">
                            <strong>Remarks:</strong>&nbsp;
                            {
                              this.state.isEdit ?
                                <div>
                                  <textarea type="text" className="form-control" name="orderNoteUpdate" id={"orderNoteUpdate"}
                                    maxLength="5000" onChange={(e) => this.ondropdownChangeagent("orderNoteUpdate", e.target.value)} placeholder="Remarks" value={this.state.orderNoteUpdate} />
                                </div> :
                                <div className="wordBreak">
                                  {this.state.viewOrder && this.state.viewOrder.note}
                                </div>
                            }
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="border p-4">
                            <strong>Error Message:</strong>&nbsp;
                            {this.state.viewOrder &&
                              this.state.viewOrder.erpOrderMessage}
                          </div>
                        </div>

                      </div>
                    </Fragment>
                }
              </div>
              {
                !this.state.isViewOpen ?
                  <div className="modal-footer bg-white text-center justify-content-center">
                    {this.state.viewOrder.isErpConfirm &&
                      this.state.viewOrder.isErpConfirm === true ? (
                      ""
                    ) : (
                      <button
                        onClick={(e) => this.submit(this.state.viewOrder)}
                        type="button"
                        data-dismiss={!this.state.isEdit ? "modal" : ""}
                        className="btn btn-primary"
                      >
                        {this.state.isEdit ? "Update" : "Confirm"}
                      </button>
                    )}

                    <button type="button" className="btn btn-dark" data-dismiss="modal" onClick={() => this.loadResult()}>
                      Cancel
                    </button>

                  </div> : null
              }

            </div>
          </div>
        </div>

        <div
          className={
            !this.state.isFilter
              ? "right-sidebarfilter hidden"
              : "right-sidebarfilter show"
          }
        >
          <div className="modal-header bg-primary rounded-0">
            <h6 className="filter-modal-title"> Filter</h6>
            <button
              type="button"
              onClick={this.toggleFilter}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                className="navbar-toggler right-sidebar-toggler pointer"
              >
                ×
              </span>
            </button>
          </div>

          <div className="card-body">
            <form>

              <div className="col-sm-12 ml-auto p-0 mb-2">
                <label>User Type</label>
                <select
                  className="form-control"
                  name="excelType"
                  value={this.state.excelType}
                  onChange={(e) => this.onChange(e)}
                  id="excelType"
                >
                  {/* <option value="">Select a Type</option> */}
                  <option value="ALL">All</option>
                  <option value="AG">Agent</option>
                  <option value="ASM">Area Sales Manager</option>
                  <option value="ASM2">Area Sales Manager 2</option>
                  <option value="SASM">Sr. AREA SALES MANAGER</option>
                </select>
              </div>

              <Userdropdown
                ondropdownChange={this.ondropdownChangeagent}
                name="agent"
                value={this.state.agent}
                className="form-control"
                placeholder="User"
              />

              <div className="form-group">
                <CustomerDropDown
                  ondropdownChange={(e) => this.ondropdownChangeagent("customer", e)}
                  placeholder="Search Customer"
                  isMulti={true}
                  filterBy={this.state.filterCustomer}
                  value={this.state.customer}
                />
              </div>

              <div className="form-group">
                <Wholesalerdropdown
                  isWhLabel={true}
                  label="Indirect Customer"
                  ondropdownChange={(e) => this.changeIndirectCus(e, "indirectCustomer")}
                  placeholder="Search Indirect Customer"
                  value={this.state.indirectCustomer}
                  filterBy={this.state.filterCustomer}
                // dontPreLoad={(this.state.agent || this.state.customer || this.state.type) ? false : true}
                // errMessage={this.state.wholesalerError}
                />
              </div>

              <div>
                {
                  brandmanagement_res && brandmanagement_res.list && brandmanagement_res.list.length > 0 ?
                    <div className="col-sm-12 ml-auto p-0 mb-2">
                      <label>Brand</label>
                      <select className="form-control" name="brand_filter" value={this.state.brand_filter}
                        onChange={(e) => this.onChange(e)}>
                        <option value="">Select Brand</option>
                        {
                          brandmanagement_res.list.map(element =>
                            // console.log(element.name)
                            element.name ? <option value={element._id} key={element._id}>{element.name}</option> : "NA"
                          )
                        }
                      </select>
                    </div>
                    : null
                }
              </div>

              <div className="col-sm-12 ml-auto p-0 mb-2">
                <label>Year</label>
                <select
                  className="form-control"
                  name="yearlyOrder"
                  value={this.state.yearlyOrder}
                  onChange={(e) => this.onChange(e)}
                  id="yearlyOrder"
                >
                  <option value="currentYear">Current Year - {new Date().getFullYear()}</option>
                  <option value="lastYear"> Last Year - {new Date().getFullYear() - 1}</option>
                  <option value="last2Years">Last 2 Years from {new Date().getFullYear() - 2}</option>
                </select>
              </div>

              <div className="col-sm-12 ml-auto p-0 mb-2">
                <label>Order Status</label>
                <Select
                  onChange={(e) => this.ondropdownChangeagent("orderStatus", e)}
                  closeMenuOnSelect={true}
                  options={this.state.orderStatusList}
                  placeholder="Order Status"
                  instanceId="order-status-id"
                  classNamePrefix="order-status-id"
                  value={this.state.orderStatus}
                />
              </div>

              <div className="row">
                <div className="form-group col-6">
                  <label htmlFor="Mobile" className="control-label">Created Start Date</label>
                  <input maxLength="10" type="date" className="form-control" name="startdate" max={this.state.maxDate} value={this.state.startdate} onChange={(e) => this.onChange(e)} id="code" placeholder="Code" />
                </div>
                <div className="form-group col-6">
                  <label htmlFor="Mobile" className="control-label">Created End Date</label>
                  <input maxLength="10" type="date" className="form-control" name="enddate" max={this.state.maxDate} value={this.state.enddate} onChange={(e) => this.onChange(e)} id="code" placeholder="Code" />
                </div>
              </div>

              {/* <div className="form-group">
                <label htmlFor="Mobile" className="control-label">Order From</label>
                <select
                  className="form-control"
                  name="requestFrom"
                  value={this.state.requestFrom}
                  onChange={this.onChange}>
                  <option value="">Select a Type</option>
                  <option value="ALL">All</option>
                  <option value="B2B">B2B</option>
                  <option value="B2C">B2C</option>
                </select>
              </div> */}

            </form>
          </div>

          <div className="col-md-6 mb-4 offset-md-3 text-center">
            <button
              onClick={this.loadResult}
              data-dismiss="modal"
              type="button"
              aria-hidden="true"
              className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1"
            >
              Apply{" "}
            </button>
            <button
              type="button"
              data-dismiss="modal"
              onClick={this.resetfilter}
              className="btn btn-outline-dark"
            >
              Reset
            </button>
          </div>
        </div>
      </main >
    );
  };

};

function mapStateToProps(state) {
  return {
    profile_single: state.profile_single,
    ordermanagement_res: state.ordermanagement_res,
    order_delete: state.order_delete,
    orderUploadresponse: state.orderUploadresponse,
    multipleorderexportresponse: state.multipleorderexportresponse,
    orderexportresponse: state.orderexportresponse,
    customermanagement_res: state.customermanagement_res,
    orderConfirm_pack: state.orderConfirm_pack,
    outstandingDaysResponse: state.outstandingDaysResponse,
    brandmanagement_res: state.brandmanagement_res,
    uommanagement_res: state.uommanagement_res,
    order_reject: state.order_reject,
    order_pdf_res: state.order_pdf_res,
    order_update: state.order_update,
    order_single: state.order_single,
    product_single: state.product_single,
  };
};

const mapDispatchToProps = (dispatch) => ({
  uommanagementlist: data => dispatch(uommanagementlist(data)),
  profilesingle: data => dispatch(profilesingle(data)),
  ordermanagementlist: (data) => dispatch(ordermanagementlist(data)),
  customermanagementlist: (data) => dispatch(customermanagementlist(data)),
  orderConfirm: (data, id) => dispatch(orderConfirm(data, id)),
  delete_order: item_id => dispatch(delete_order(item_id)),
  orderexport: (data) => dispatch(orderexport(data)),
  multipleOrderExport: (data) => dispatch(multipleOrderExport(data)),
  outstandingDays: (id) => dispatch(outstandingDays(id)),
  brandmanagementlist: data => dispatch(brandmanagementlist(data)),
  orderReject: (id) => dispatch(orderReject(id)),
  order_pdf: (item_id) => dispatch(order_pdf(item_id)),
  orderUpdate: (data, id) => dispatch(orderUpdate(data, id)),
  orderSingle: (data) => dispatch(orderSingle(data)),
  productsingle: (data) => dispatch(productsingle(data))
});

Ordermanagement = connect(mapStateToProps, mapDispatchToProps)(Ordermanagement);

export default Ordermanagement;
