import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { agent_search } from '../../actions';
import ErrorView from '../../components/errorView';
class Userdropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: '',
      options: [],
      srASMoptions: []
    };
  }

  componentDidMount() {
    let { type } = this.props;
    if (type !== undefined && type === "ASM") {
      let data = {
        type: ["ASM", "ASM2"]
      };
      data.sortBy = "name";
      data.sort = "asc";
      this.props.agent_search(data);
    } else if (type !== undefined && type === "SASM") {
      let data = {
        type: "SASM"
      };
      data.sortBy = "name";
      data.sort = "asc";
      this.props.agent_search(data);
    } else if (type !== undefined && type === "all") {
      let data = {
        type: ["AG", "ASM", "ASM2", "WHS", "RTL", "SASM"]
      };
      data.sortBy = "name";
      data.sort = "asc";
      this.props.agent_search(data);
    } else if (type !== undefined && type === "AG") {
      let data = {
        type: ["AG"]
      };
      data.sortBy = "name";
      data.sort = "asc";
      this.props.agent_search(data);
    } else {
      let data = {
        type: ["AG", "ASM", "ASM2", "WHS", "CO", "CA"]
      };
      data.sortBy = "name";
      data.sort = "asc";
      this.props.agent_search(data);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      if (nextProps.clear === true) {
        this.setState({ srASMoptions: [] })
      }
      if (nextProps.agent_response_search !== undefined && nextProps.agent_response_search.list && nextProps.agent_response_search.success === true) {
        this.setState({ options: [], srAsmOptions: [] }, () => {
          nextProps.agent_response_search.list.forEach(item => {
            if ((nextProps.agent_response_search.list.length !== this.state.srAsmOptions) || (nextProps.agent_response_search.list.length !== this.state.options)) {
              if (item.type === "SASM") {
                this.state.srAsmOptions.push({ value: item._id, label: item.name, type: item.type })
              } else {
                this.state.options.push({ value: item._id, label: item.name, type: item.type })
              }
            }
          });
        })
      }
    }
    return true;
  }

  render() {
    const {
      isDisabled, type,
      ondropdownChange,
      name,
      placeholder, value, isMulti, isSearchable, noLable, labelValue, srASMusers, errMessage
    } = this.props;

    return (
      <div>
        {noLable && !noLable ? null : <label className="control-label">{labelValue ? labelValue : type !== undefined && type === "ASM" ? "ASM" : type === "SASM" ? "Sr. ASM" : "User"} </label>}
        {/* {console.log(type)} */}
        <Select
          {...(value === "") ? { value: "" } : {}}
          isDisabled={isDisabled}
          name={name}
          value={value}
          instanceId="user-id"
          onChange={value => {
            ondropdownChange(name, value);
          }}
          classNamePrefix={type || 'user'}
          // value={value}
          options={srASMusers ? srASMusers : type === "SASM" ? this.state.srASMoptions : this.state.options}
          placeholder={placeholder !== undefined ? placeholder : "Agent"}
          isMulti={isMulti}
          isSearchable={isSearchable}
        />
        <ErrorView message={errMessage} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { agent_response_search: state.agent_response_search };
}

const mapDispatchToProps = dispatch => ({
  agent_search: data => dispatch(agent_search(data))
});

Userdropdown = connect(
  mapStateToProps,
  mapDispatchToProps
)(Userdropdown);

export default Userdropdown;
